import React from 'react';

const HelpImg = () => {
    return (
        <div className="nav_fill_effect">
            <svg width="49" height="54" viewBox="0 0 49 54" fill="none" xmlns="http://www.w3.org/2000/svg" className="nav_fill">
                <path d="M24.5309 0.753906C21.7792 0.753906 19.0893 1.56987 16.8014 3.09862C14.5135 4.62737 12.7302 6.80025 11.6772 9.34246C10.6242 11.8847 10.3487 14.6821 10.8855 17.3809C11.4223 20.0797 12.7474 22.5587 14.6931 24.5044C16.6388 26.4501 19.1179 27.7752 21.8167 28.312C24.5155 28.8489 27.3128 28.5733 29.8551 27.5203C32.3973 26.4673 34.5702 24.6841 36.0989 22.3961C37.6277 20.1082 38.4436 17.4183 38.4436 14.6666C38.4436 10.9767 36.9778 7.43799 34.3687 4.82885C31.7595 2.21971 28.2208 0.753906 24.5309 0.753906ZM24.5309 23.6105C24.2361 23.6105 23.9479 23.5231 23.7027 23.3593C23.4576 23.1955 23.2665 22.9627 23.1537 22.6903C23.0409 22.4179 23.0114 22.1182 23.0689 21.8291C23.1264 21.5399 23.2684 21.2743 23.4768 21.0658C23.6853 20.8574 23.9509 20.7154 24.2401 20.6579C24.5292 20.6004 24.829 20.6299 25.1013 20.7427C25.3737 20.8555 25.6065 21.0466 25.7703 21.2917C25.9341 21.5369 26.0215 21.8251 26.0215 22.1199C26.0215 22.5152 25.8645 22.8944 25.5849 23.1739C25.3054 23.4535 24.9262 23.6105 24.5309 23.6105ZM25.6658 15.9049V18.3903H23.4298V13.6729H25.5416C26.1679 13.6729 26.7686 13.424 27.2115 12.9811C27.6544 12.5382 27.9032 11.9375 27.9032 11.3112C27.9032 10.6848 27.6544 10.0841 27.2115 9.64122C26.7686 9.19832 26.1679 8.9495 25.5416 8.9495H24.0509C23.4252 8.95029 22.8253 9.1992 22.3829 9.64165C21.9404 10.0841 21.6915 10.684 21.6907 11.3097V11.9437H19.4547V11.3097C19.4563 10.0915 19.9412 8.92364 20.8029 8.06251C21.6645 7.20137 22.8327 6.71729 24.0509 6.7165H25.5416C26.7477 6.71926 27.9044 7.19613 28.762 8.04419C29.6197 8.89226 30.1095 10.0435 30.1258 11.2495C30.1421 12.4556 29.6836 13.6197 28.8492 14.4906C28.0148 15.3615 26.8714 15.8695 25.6658 15.9049Z" fill="#838383"/>
                <path d="M19.9918 38.553H20.7382V34.0027H19.9918V35.9538H17.8509V34.0027H17.1045V38.553H17.8509V36.5626H19.9918V38.553ZM24.5623 33.9962H22.0482V38.553H24.5623V37.9441H22.7945V36.5495H24.3659V35.9407H22.7945V34.605H24.5623V33.9962ZM25.809 34.0027V38.553H28.0939V37.9506H26.5553V34.0027H25.809ZM29.7918 36.0978V34.6116H30.6168C31.1995 34.6116 31.4614 34.8866 31.4614 35.358C31.4614 35.8163 31.1995 36.0978 30.6168 36.0978H29.7918ZM32.2274 35.358C32.2274 34.6181 31.6971 34.0027 30.6168 34.0027H29.0455V38.553H29.7918V36.7067H30.6168C31.8018 36.7067 32.2274 36.0061 32.2274 35.358Z" fill="#838383"/>
            </svg>
        </div>
    );
}
export default HelpImg;