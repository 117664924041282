import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputAdornment,
  SvgIcon,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";

import AddAdmin from "./AddAdmin";
import UserHierarchy from "./userHierarchy";
import DeleteAdmin from "./DeleteAdmin";
import AdminEditImg from "../../../common/assets/images/components/admineditsvg";
import DeleteImg from "../../../common/assets/images/components/Deleteimg";
import { Link } from "react-router-dom";
import Back from "../../../common/assets/images/Back.svg";
import Close from "../../../common/assets/images/Close.svg";
const ALPHA = /^[a-zA-Z]+$/;
import "../../../dataEntry/styles/DataEntry.scss";
import CheckIcon from "@mui/icons-material/Check";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { activateAdmin } from "../../reducers/wardThunk";
import { admindata } from "../../reducers/admininitialstate";
import { ToastContainer, toast } from "react-toastify";

import {
  addAdminDetails,
  getmapedAdminDetail,
  getAdminDetail,
  getBranchDetail,
  updateAdminDetails,
  setEditAdminData,
  getUserRoles,
} from "../../reducers/adminThunk";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../../reducers/branchDataThunk";
import { CustomPagination } from "../../../Dashboard/components/DashboardScreen";
import { DataGrid } from "@mui/x-data-grid";
/* import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #ccc", // Replace #ccc with the color of your choice
  },
})); */

const AdminListing = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const admin = useSelector((state) => state.admin);
  const login = useSelector((state) => state.login);
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch);

  const [selectedBranches, setselectedBranches] = useState([]);
  const [showFormdata, setShowForm] = useState(false);

  const [addAdminData, setAddAdminData] = useState(admin.admindata);
  const [deleteSelectedAdmin, setDelete] = React.useState(false);
  const [SelectedAdmin, setSelectedAdmin] = useState([]);
  const [adminMap, setAdminMap] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [contentWidth, setContentWidth] = React.useState();
  const [contentHeight, setContentHeight] = React.useState();
  const [updatedUser, setUpdatedUser] = React.useState();
  const [userRoles, setUserRolesList] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(-1);
  const [selectedStatus, setSelectedStatus] = React.useState(-1);
  const [searchText, setSearchText] = React.useState();
  const hospital_id = user.userProfile?.hospital_id;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRoles());
  }, []);

  useEffect(() => {
    getFilteredAdmins();
  }, [selectedRole, hospital_id]);

  useEffect(() => {
    const timeOutId = setTimeout(() => getFilteredAdmins(), 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  const getFilteredAdmins = () => {
    if (hospital_id) {
      const req = {
        hospital_id: hospital_id,
      };
      selectedRole != -1 ? (req.role_id = selectedRole) : null;
      searchText ? (req.searchText = searchText) : null;
      dispatch(getAdminDetail(req));
    }
  };

  useEffect(() => {
    updateFilteredUsers(selectedStatus);
  }, [adminMap]);

  const updateFilteredUsers = (status) => {
    if (status == -1) {
      setFilteredUsers([].concat(adminMap));
    } else {
      setFilteredUsers(
        adminMap.filter((users) => users.deleted_flag == status)
      );
    }
  };

  const filterByStatus = (status) => {
    setSelectedStatus(status);
    updateFilteredUsers(status);
  };

  useEffect(() => {
    if (admin && admin.userRolesList) setUserRolesList(admin.userRolesList);
    else setUserRolesList([]);
  }, [admin.userRolesList]);

  useEffect(() => {
    if (props.open) setAddAdminData(admin.admindata);
  }, [admin.admindata, props.open]);

  const handleNext = (user) => {
    setUpdatedUser(user);
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 100);
  };

  const closeModal = () => {
    const hospital_id = user.userProfile?.hospital_id;
    getFilteredAdmins();
    dispatch(getmapedAdminDetail(hospital_id));
    handleClose();
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
    dispatch(setEditAdminData({}));
    // dispatch(getAdminDetail({ hospital_id }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const hospital_id = user.userProfile?.hospital_id;
    if (hospital_id) {
      dispatch(getAdminDetail({ hospital_id }));
      dispatch(getBranchDetail({ hospital_id }));
      dispatch(getmapedAdminDetail(hospital_id));
      updateDimensions();
    }
  }, [hospital_id]);

  useEffect(() => {
    let { branchList, adminMaping, adminList } = admin;
    adminList =
      searchText && searchText.length ? adminList : addPrimaryUser(adminList);

    const mapping = [];
    if (adminList && adminList.length > 0) {
      for (const admin of adminList) {
        mapping.push({ ...admin, branch: [], selectedBranches: [] });
      }
    }

    if (branchList && branchList.length > 0) {
      for (const map of mapping) {
        map["branches"] = branchList;
      }
    }
    if (adminMaping && adminMaping.length > 0) {
      for (const map of mapping) {
        map["selectedBranches"] = adminMaping
          .filter((bm) => bm.user_id === map.id)
          .map((bm) => bm.branch_id);
      }
    }
    setAdminMap(mapping);
  }, [admin.branchList, admin.adminMaping, admin.adminList]);

  const addPrimaryUser = (adminsList) => {
    let userData = sessionStorage.getItem("user");
    userData = JSON.parse(userData);

    const primaryUser = {
      id: -1,
      admin_name: userData.username,
      email: userData.email,
      contact: userData.phone,
      role_name: "Primary user",
      deleted_flag: userData.deleted_flag,
    };
    adminsList = adminsList.length
      ? adminsList[0].id == -1
        ? adminsList
        : [primaryUser].concat(adminsList)
      : (adminsList = [primaryUser]);

    return adminsList;
  };

  const showForm = () => {
    setShowForm(!showFormdata);
  };

  const updateDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setContentWidth((width * 60) / 100);
    setContentHeight((height * 60) / 100);
  };

  const handleChange = (event) => {
    let SelectedAdmin = [...selectedBranches];
    if (event.target.checked) {
      SelectedAdmin.push(Number(event.target.value));
    } else {
      SelectedAdmin = SelectedAdmin.filter(
        (s) => s != Number(event.target.value)
      );
    }

    setselectedBranches(SelectedAdmin);
  };

  const handleOpen = (params) => {
    const data = admin.adminList.filter((d) => d.id === params.row.id);
    setAddAdminData(data[0]);

    dispatch(setEditAdminData(data[0])).then(() => {
      setOpen(true);
    });
  };

  const deleteAdminData = (params) => {
    const data = admin.adminList.filter((d) => d.id === params.row.id);
    setSelectedAdmin(data[0]);
    setDelete(true);
  };

  const activateAdminRecord = (params) => {
    // console.log(params);
    dispatch(activateAdmin(params.row.id)).then(() => {
      toast.success("User Activated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getAdminDetail({ hospital_id }));
    });
  };

  const closeDialog = () => {
    getFilteredAdmins();
    setDelete(false);
  };

  useEffect(() => {
    if (branch.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [branch.errorMessage]);

  useEffect(() => {
    if (branch.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [branch.successMessage]);

  const columns = [
    {
      field: "admin_name",
      headerName: <span className="gridheader_font">User Name</span>,
      width: 250,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.admin_name}</span>
      ),
    },
    {
      field: "contact",
      headerName: <span className="gridheader_font">Contact Number</span>,
      width: 100,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.contact}</span>
      ),
    },
    {
      field: "email",
      headerName: <span className="gridheader_font">Email Id</span>,
      width: 175,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.email}</span>
      ),
    },
    {
      field: "role_name",
      headerName: <span className="gridheader_font">User Role</span>,
      width: 185,
      renderCell: (params) => (
        <span className="dadata">{params.row.role_name}</span>
      ),
    },
    {
      field: "Branches",
      headerName: <span className="gridheader_font">Assigned Branches</span>,
      flex: 0.6,
      width: 85,
      renderCell: (params) => (
        <span className="dadata">
          {params.row.id == -1
            ? admin?.branchList.length
            : params.row.selectedBranches.length}
        </span>
      ),
    },
    {
      field: "deleted_flag",
      flex: 0.4,
      headerName: <span className="gridheader_font">Status</span>,
      width: 85,
      renderCell: (params) => (
        <span className="dadata">
          {params.row.deleted_flag ? "Deleted" : "Active"}
        </span>
      ),
    },

    {
      field: "Edit",
      headerName: <span className="gridheader_font">Actions</span>,
      width: 185,
      renderCell: (params) => (
        <strong>
          {params.row.id == -1 ? (
            ""
          ) : (
            <strong>
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: 10,
                  background: "#6572E4",
                  //background: "#F98F60",
                  borderRadius: 4,
                  fontSize: 10,
                  boxShadow: "none",
                  textTransform: "none",
                }}
                className="view_Reports"
                onClick={() => handleOpen(params)}
              >
                Edit
              </Button>

              {params.row.deleted_flag ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginLeft: 10,
                    //background: "#F98F60",

                    background: "#008000",
                    borderRadius: 4,
                    fontSize: 10,
                    boxShadow: "none",
                    textTransform: "none",
                  }}
                  className="view_Reports"
                  onClick={() => activateAdminRecord(params)}
                >
                  Activate
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    marginLeft: 10,
                    background: "#FF6347",
                    borderRadius: 4,
                    fontSize: 10,
                    boxShadow: "none",
                    textTransform: "none",
                  }}
                  className="view_Reports"
                  onClick={() => deleteAdminData(params)}
                >
                  Delete
                </Button>
              )}
            </strong>
          )}
        </strong>
      ),
    },
  ];

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <Grid
                  container
                  style={{
                    borderBottom: "0.8px solid rgba(101, 114, 228, 0.4)",
                  }}
                >
                  <Grid item xs={12} sm={2}>
                    <h4 className="branch-header">List Of Users</h4>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box>
                    <form onSubmit={(event) => {
                    event.preventDefault(); // Prevent page refresh on form submission
                    // Perform search logic here
                    }}>
                      <TextField
                        className="select_usertype"
                        size="small"
                        style={{ margin: "5px 5px" }}
                        onChange={(event) => {
                          setSearchText(event.target.value);
                        }}
                        value={searchText}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Search Users"
                      />
                    </form>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <label
                      htmlFor="Sort"
                      className="dataentry_label"
                      style={{
                        display: "block",
                        fontSize: "16px",
                        padding: "0px 20px",
                        marginTop: "0px",
                      }}
                    >
                      Status
                      <Select
                        labelId="Role"
                        id="status"
                        name="status"
                        label=""
                        size="small"
                        className="select_usertype"
                        value={selectedStatus}
                        onChange={(event) => {
                          filterByStatus(event.target.value);
                        }}
                      >
                        <MenuItem value="-1">All</MenuItem>
                        <MenuItem value="0">Active</MenuItem>
                        <MenuItem value="1">Deleted</MenuItem>
                      </Select>
                    </label>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <label
                      htmlFor="Sort"
                      className="dataentry_label"
                      style={{
                        display: "block",
                        fontSize: "16px",
                        padding: "0px 20px",
                        marginTop: "0px",
                      }}
                    >
                      Roles
                      <Select
                        labelId="Role"
                        id="role_id"
                        name="role_id"
                        label=""
                        size="small"
                        className="select_usertype"
                        value={selectedRole}
                        onChange={(event) => {
                          setSelectedRole(event.target.value);
                        }}
                      >
                        <MenuItem value="-1">All Roles</MenuItem>
                        {userRoles.map((num) => (
                          <MenuItem value={num.role_id}>
                            {num.role_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="outlined"
                      className="branch_Add"
                      onClick={handleClickOpen}
                    >
                      + Add User
                    </Button>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={12} sm={12} className="header">
                    <div style={{ height: 631, width: "100%" }}>
                      <DataGrid
                        rows={filteredUsers}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        showCellRightBorder={true}
                        showColumnRightBorder={true}
                        getRowId={(row) => row.id}
                        components={{
                          Footer: CustomPagination,
                          NoRowsOverlay: () => (
                            <Stack
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              No Users Found
                            </Stack>
                          ),
                          NoResultsOverlay: () => (
                            <Stack
                              height="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              No Users Found
                            </Stack>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <Dialog open={open} onClose={handleClose} className="dialogWidth">
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "0.8px solid",
            borderColor: "rgba(101, 114, 228, 0.4)",
          }}
        >
          <span>
          <Link
            className="FIle-left"
            onClick={props.onClose}
            style={{ marginRight: "10px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          {!admin?.admindata?.id ? "Add" : "Update"} User
          </span>
          <Link className="FIle-right-extreme" onClick={handleClose}>
            <img src={Close} alt="" style={{ marginRight: "0px" }} />
          </Link>
        </DialogTitle>
        <DialogContent>
          <div
          /* style={{
              width: contentWidth + "px",
              height: contentHeight + "px",
            }} */
          >
            <AddAdmin
              nextStep={handleNext}
              SelectedAdmin={SelectedAdmin}
              closeModal={closeModal}
            />
          </div>
        </DialogContent>
      </Dialog>
      {branch.errorMessage && (
        <Snackbar
          open={branch.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {branch.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {branch.successMessage && (
        <Snackbar
          open={branch.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {branch.successMessage}
          </Alert>
        </Snackbar>
      )}

      {deleteSelectedAdmin && (
        <DeleteAdmin
          SelectedAdmin={SelectedAdmin}
          open={deleteSelectedAdmin}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default AdminListing;
