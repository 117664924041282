import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Snackbar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
const ALPHA = /^[a-zA-Z ]+$/;
const Numerics = /^[0-9]+$/;
import { TELUGU_STATES } from "../../Admin/utils/mockdata";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";

import { Stepper, Step, StepLabel } from "@mui/material";
import "../../login/styles/login.scss";
import "../../dataEntry/styles/DataEntry.scss";
import SubscPopUp from "./SubscriptionPopup";
import { ToastContainer, toast } from "react-toastify";
import {
  accountcreation,
  setFormTab,
  getusertypeDetails,
  subscriptionPopup,
  multiPartFileUpload,
} from "../reducers/signupDataThunk";

import { setErrorMessage } from "../../dataEntry/reducers/patientDataThunk";
import { esES } from "@mui/x-date-pickers/locales";

const AccCreation = (props) => {
  const [checked, setChecked] = React.useState(false);
  // const [defaultCheck, setDefaultCheck] = useState({
  //   Disclaimercheck: false,
  // });
  const signup = useSelector((state) => state.signup);
  const patient = useSelector((state) => state.patient);
  const [userTypeOptions, setUserTypeOptions] = React.useState([]);
  const [selectedFile, setSelectedFile] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ALPHA = /^[a-zA-Z ]*$/;
  const pincodeRegex = /^[1-9][0-9]{9}$/;
  const Numerics = /^[0-9 ]*$/;
  const testEmail = /^[A-Z0-9._!&%$#@+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const [steps, setSteps] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [Disclaimer, setDisclaimerOpen] = React.useState(false);
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState();
  const [showInstErrorMessage, setShowInstErrorMessage] = useState();
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState();
  const [showProfessionErrorMessage, setShowProfessionErrorMessage] =
    useState();
  const [showIANErrorMessage, setShowIANErrorMessage] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState();
  const [showPincodeErrorMessage, setShowPincodeErrorMessage] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [addACCData, setaddACCData] = useState({
    password: "",
    showPassword: false,
  });
  const [userType, setUserTypeData] = React.useState([]);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  const handleClickShowPassword = () => {
    setaddACCData({ ...addACCData, showPassword: !addACCData.showPassword });
  };

  const AcceptDisclaimer = (event) => {
    setChecked(true);
    setDisclaimerOpen(false);
  };
  const RejectDisclaimer = (event) => {
    setChecked(false);
    setDisclaimerOpen(false);
  };

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 3000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    dispatch(getusertypeDetails());
  }, []);

  useEffect(() => {
    if (signup && signup.userTypeList) setUserTypeData(signup.userTypeList);
    else setUserTypeData([]);
  }, [signup.userTypeList]);

  useEffect(() => {
    setUserTypeOptions([...signup.userTypeList]);
  }, [signup.userTypeList]);

  // useEffect(() => {
  //   setaddACCData(signup.accountcreation);
  // }, [signup.accountcreation]);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const DisclaimerOpen = () => {
    setDisclaimerOpen(true);
  };
  const handleClose = () => {
    setDisclaimerOpen(false);
  };

  const verifyUserName = (event) => {
    if (ALPHA.test(event.target.value)) {
      setShowErrorMessage("Please Enter Alphabets and Space");
      setErrorFields(Object.assign({}, errorFields, { name: true }));
      document.getElementById("name").focus();
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { name: false }));
      setShowErrorMessage("");
    }
  };

  const verifyPassword = (event) => {
    const Passwordcheck =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!Passwordcheck.test(event.target.value)) {
      setShowPasswordErrorMessage(
        "Password must contain a minimum of eight characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setErrorFields(Object.assign({}, errorFields, { password: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { password: false }));
      setShowPasswordErrorMessage("");
    }
  };
  const verifyPrimaryContactNo = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage("User Mobile Number Should be 10 Digits");
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: true })
      );
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: false })
      );
      setShowMobileErrorMessage("");
    }
    /* if (event.target.value.length == 10) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: false })
      );
    } else {
      toast.error("Phone Number should be 10 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("PrimaryContactNo").focus();
      setErrorFields(
        Object.assign({}, errorFields, { primary_contact_no: true })
      );
    } */
  };
  const verifyPincode = (event) => {
    const pincodeRegex1 = /^[1-9][0-9]{5}$/;
    if (!pincodeRegex1.test(event.target.value)) {
      setShowPincodeErrorMessage("pincode should be 6 Digits");
      setErrorFields(Object.assign({}, errorFields, { pincode: true }));
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { pincode: false }));
      setShowPincodeErrorMessage("");
    }
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    //const filevalue = event.target.files;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...addACCData };
    newFormData[fieldname] = fieldvalue;
    //newFormData[fieldname] = filevalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setaddACCData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    // console.log("The selected file is, ", event.target.files);
    event.preventDefault();
    if (selectedFile.length !== 0) {
      // console.log("Data That the Application is sending is,");
      // console.log(addACCData);
      // console.log("About to call Axios API");
      for (const key in addACCData) {
        if (!addACCData[key]) {
          addACCData[key] = "NA";
        }
      }
      const req = { addACCData };
      dispatch(accountcreation(req))
        .unwrap()
        .then(async (resdata) => {
          // console.log(resdata.response);
          // console.log("The selected file is, ", event.target.files);

          if (resdata.error != false) {
            // dispatch(setErrorMessage(resdata.message));
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (selectedFile) {
            await uploadFile(resdata.response.user_id);
            props.nextStep();
          } else {
            props.nextStep();
          }
        });
    } else {
      toast.error("Please Upload Identity Proof", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const uploadRefImages = (event) => {
    setSelectedFile(event.target.files[0]);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
  };

  const uploadFile = (user_id) => {
    let currentFile = selectedFile;
    const formData_img = new FormData();
    const document_type = "IDProof";
    formData_img.append("user_id", user_id);
    // formData_img.append("study_id", study_id);
    formData_img.append("document_type", document_type);
    // formData_img.append("Puid", Puid);
    formData_img.append("file", currentFile);
    dispatch(multiPartFileUpload(formData_img))
      .unwrap()
      .then((resdata) => {
        // console.log("The referal response data is", resdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /* const submitform = (newState) => () => {
    // console.log("abcdef");
    //setState({ open: true, ...newState });
  }; */

  return (
    <React.Fragment sx={{ maxWidth: "95%" }}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <form
          id="acc-creation-form"
          onSubmit={HandleAddFormSubmit}
          sx={{ maxWidth: "99%" }}
        >
          <Grid container>
            <Grid item xs={12} sm={12}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                Create an Account
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="User Type"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Choose a User Type
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <Select
                labelId="user Type"
                id="user_type"
                name="user_type"
                label=""
                size="small"
                className="dataentry_select_edu"
                onChange={handleAddFormChange}
                value={addACCData.user_type}
                required
              >
                {userType.map((num) => (
                  <MenuItem value={num.user_type_id}>{num.user_type}</MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Name"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                User Full Name
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="name"
                name="name"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={(e) => {
                  handleAddFormChange(e);
                  //verifyUserName(e);
                }}
                required
                value={addACCData.name}
                type="text"
                error={errorFields.name && showNameErrorMessage}
                //helperText={showErrorMessage}
                errorText={showNameErrorMessage}
                helperText={
                  errorFields.name && showNameErrorMessage
                    ? showNameErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!ALPHA.test(event.key)) {
                    setShowNameErrorMessage(
                      "Only Alphabets and Space are acceptable"
                    );
                    setErrorFields(
                      Object.assign({}, errorFields, { name: true })
                    );
                    event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, { name: false })
                    );
                    setShowNameErrorMessage("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Phone Number"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                User Mobile Number
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="contact_number"
                name="contact_number"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                /* className={
                "dataentry_input " +
                (errorFields.primary_contact_no ? "errorField" : "")
              } */
                //onChange={handleAddFormChange}
                onChange={(e) => {
                  handleAddFormChange(e);
                  verifyPrimaryContactNo(e);
                }}
                //onBlur={verifyPrimaryContactNo}
                value={addACCData?.primary_contact_no}
                required
                error={errorFields.primary_contact_no && showMobileErrorMessage}
                //helperText={showErrorMessage}
                errorText={showMobileErrorMessage}
                helperText={
                  errorFields.primary_contact_no && showMobileErrorMessage
                    ? showMobileErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!Numerics.test(event.key) && event.key != "Backspace") {
                    event.preventDefault();
                    setShowMobileErrorMessage("Only Numerics are acceptable");
                    setErrorFields(
                      Object.assign({}, errorFields, {
                        primary_contact_no: true,
                      })
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Email ID"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                User Email ID
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="email_address"
                name="email_address"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                value={addACCData.email_address}
                type="email"
                required
                error={errorFields.email_address && showEmailErrorMessage}
                //helperText={showErrorMessage}
                errorText={showEmailErrorMessage}
                helperText={
                  errorFields.email_address && showEmailErrorMessage
                    ? showEmailErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!testEmail.test(event.target.value)) {
                    setShowEmailErrorMessage("Please Enter Valid Email Id");
                    setErrorFields(
                      Object.assign({}, errorFields, { email_address: true })
                    );

                    //event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, {
                        email_address: false,
                      })
                    );
                    setShowEmailErrorMessage("");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <label
                htmlFor="password"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Password
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                required
                id="password"
                name="password"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                //onChange={handleAddFormChange}
                value={addACCData.password}
                type={addACCData.showPassword ? "text" : "password"}
                error={errorFields.password && showPasswordErrorMessage}
                //helperText={showErrorMessage}
                errorText={showPasswordErrorMessage}
                helperText={
                  errorFields.password && showPasswordErrorMessage
                    ? showPasswordErrorMessage
                    : ""
                }
                onChange={(e) => {
                  handleAddFormChange(e);
                  verifyPassword(e);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => handleClickShowPassword()}>
                      {addACCData.showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Profession"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Profession
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="Profession"
                name="Profession"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                required
                error={errorFields.Profession && showProfessionErrorMessage}
                //helperText={showErrorMessage}
                errorText={showProfessionErrorMessage}
                helperText={
                  errorFields.Profession && showProfessionErrorMessage
                    ? showProfessionErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!ALPHA.test(event.key)) {
                    setShowProfessionErrorMessage(
                      "Only Alphabets and Space are acceptable"
                    );
                    setErrorFields(
                      Object.assign({}, errorFields, { Profession: true })
                    );
                    event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, { Profession: false })
                    );
                    setShowProfessionErrorMessage("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Institution Name"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Institution Name
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="Institution_name"
                name="Institution_name"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                error={errorFields.Institution_name && showInstErrorMessage}
                //helperText={showErrorMessage}
                errorText={showInstErrorMessage}
                helperText={
                  errorFields.Institution_name && showInstErrorMessage
                    ? showInstErrorMessage
                    : ""
                }
                required
                onKeyDown={(event) => {
                  if (!ALPHA.test(event.key)) {
                    setShowInstErrorMessage(
                      "Only Alphabets and Space are acceptable"
                    );
                    setErrorFields(
                      Object.assign({}, errorFields, { Institution_name: true })
                    );
                    event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, {
                        Institution_name: false,
                      })
                    );
                    setShowInstErrorMessage("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Institution Alias Name"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Institution Alias Name
              </label>
              <TextField
                id="Institution_alias_name"
                name="institution_alias_name"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                error={
                  errorFields.institution_alias_name && showIANErrorMessage
                }
                //helperText={showErrorMessage}
                errorText={showIANErrorMessage}
                helperText={
                  errorFields.institution_alias_name && showIANErrorMessage
                    ? showIANErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!ALPHA.test(event.key)) {
                    setShowIANErrorMessage(
                      "Only Alphabets and Space are acceptable"
                    );
                    setErrorFields(
                      Object.assign({}, errorFields, {
                        institution_alias_name: true,
                      })
                    );
                    event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, {
                        institution_alias_name: false,
                      })
                    );
                    setShowIANErrorMessage("");
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Address"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Address
              </label>
              <TextField
                multiline
                maxRows={4}
                id="address"
                name="address"
                label=""
                variant="outlined"
                className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                InputProps={{
                  inputProps: {
                    maxLength: 250,
                    minLength: 3,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="City"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                City
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="city"
                name="city"
                label=""
                variant="outlined"
                required
                //className="dataentry_input"
                size="small"
                onChange={handleAddFormChange}
                className={
                  "dataentry_input " + (errorFields.city ? "errorField" : "")
                }
                error={errorFields.city && showErrorMessage}
                //helperText={showErrorMessage}
                errorText={showErrorMessage}
                helperText={
                  errorFields.city && showErrorMessage ? showErrorMessage : ""
                }
                onKeyDown={(event) => {
                  if (!ALPHA.test(event.key)) {
                    setShowErrorMessage(
                      "Only Alphabets and Space are acceptable"
                    );
                    setErrorFields(
                      Object.assign({}, errorFields, { city: true })
                    );
                    event.preventDefault();
                  } else {
                    handleAddFormChange(event);
                    setErrorFields(
                      Object.assign({}, errorFields, { city: false })
                    );
                    setShowErrorMessage("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="State"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                State
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <Select
                labelId="state"
                id="state"
                name="state"
                required
                label=""
                size="small"
                className="dataentry_select_edu"
                onChange={handleAddFormChange}
              >
                {TELUGU_STATES.map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                htmlFor="Pincode"
                className="dataentry_label"
                style={{ display: "block" }}
              >
                Pincode
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
              </label>
              <TextField
                id="pincode"
                name="pincode"
                label=""
                variant="outlined"
                required
                //className="dataentry_input"

                className={
                  "dataentry_input " + (errorFields.pincode ? "errorField" : "")
                }
                size="small"
                onChange={(e) => {
                  handleAddFormChange(e);
                  verifyPincode(e);
                }}
                error={errorFields.pincode && showPincodeErrorMessage}
                //helperText={showErrorMessage}
                errorText={showPincodeErrorMessage}
                helperText={
                  errorFields.pincode && showPincodeErrorMessage
                    ? showPincodeErrorMessage
                    : ""
                }
                onKeyDown={(event) => {
                  if (!Numerics.test(event.key) && event.key != "Backspace") {
                    setShowPincodeErrorMessage("Only Numerics are acceptable");
                    setErrorFields(
                      Object.assign({}, errorFields, { pincode: true })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} className="uploadClass">
              <label
                htmlFor="btn-upload-signup"
                className="dataentry_label"
                style={{ display: "block", marginBottom: 10 }}
              >
                Verify Identity
                <span style={{ color: "red", fontSize: "15px" }}>*</span>
                <input
                  className="upload-reports"
                  id="btn-upload-signup"
                  name="fileupload"
                  style={{
                    margin: "20px 10px",
                    //display: "none",
                    cursor: "pointer",
                  }}
                  type="file"
                  hidden
                  // onChange={handleAddFormChange}
                  onChange={uploadRefImages}
                />
                <Button
                  className="upload-reports"
                  color="primary"
                  variant="contained"
                  component="span"
                  size="small"
                  style={{ display: "flex", width: "50%" }}
                >
                  Upload Document
                </Button>
                {selectedFile.length !== 0 ? (
                  <div>{selectedFile.name}</div>
                ) : (
                  <div></div>
                )}
              </label>
              {/* <Button
              className="upload-reports"
              color="primary"
              variant="contained"
              component="span"
              size="small"
              type="file"
              style={{ margin: "10px 10px", float: "left" }}
            >
              Upload Document
            </Button> */}
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <input
              name="DisclaimerCheck"
              type="checkbox"
              checked={checked}
              size="large"
              onChange={() => DisclaimerOpen()}
            />
            <p
              style={{
                float: "none",
                cursor: "pointer",
                fontSize: "12px",
              }}
              className="frgtpwdlink"
              onClick={() => DisclaimerOpen()}
            >
              Application Disclaimer & Privacy Policy
            </p>
          </div>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {checked && isValid && selectedFile.length !== 0 ? (
                <Button
                  variant="contained"
                  className="purple-add, sign-up-button"
                  type="submit"
                >
                  <span>Continue</span>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled
                  className="sign-up-button"
                  //className="gray-add"
                >
                  <span>Continue</span>
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div
                className="account_bottom"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Have an account already?
                <Link
                  to="/login"
                  style={{ color: "#6572E4", paddingLeft: "5px" }}
                >
                  Login
                </Link>
              </div>
            </Grid>
          </Grid>
        </form>

        <Dialog open={Disclaimer} onClose={handleClose}>
          <DialogTitle>
            <Link
              className="FIle-left"
              onClick={handleClose}
              style={{ marginRight: "20px" }}
            >
              <img
                src={Back}
                className="file-img-left"
                alt=""
                style={{ width: "12px" }}
              />
            </Link>
            Disclaimer & Privacy Policy
            <Link className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} style={{ paddingLeft: "65%" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <div className="terms_box">
              <h4 style={{ textAlign: "center" }}>Application Disclaimer</h4>
              <p>
                This application is intended for basic screening and educational
                purposes only. Persons using analysis for medical purposes
                should not rely solely on the accuracy of the data herein. While
                the data may be updated periodically, users should talk to their
                healthcare professional for further guidance.
              </p>
              <p>
                This application is not a substitute for medical advice. Users
                of the application should consult their healthcare professional
                and co-relate with other medical conditions, symptoms and test
                reports before making any health, medical or other decisions
                based upon the data contained here in and analysis obtained.
              </p>
              <p>
                Any and all liability arising directly or indirectly from the
                use of this application is hereby disclaimed. The information
                herein is provided &quot;as is&quot; and without any warranty
                expressed or implied. All direct, indirect, special, incidental,
                consequential or punitive damages arising from any use of this
                application or data contained herein is disclaimed and excluded.
              </p>

              <h4 style={{ textAlign: "center" }}>Privacy Policy</h4>
              <h4>GENERAL</h4>
              <p>
                This application with the name of PreSco ("App/PreSco") is
                operated by Avyantra Health Technologies Pvt Ltd("/We/Our/Us").
                We are committed to protecting and respecting your privacy. We
                do collect your personal information and process your personal
                data in accordance with the IT Act, 2000 (21 of 2000) and other
                national and state laws which relate to the processing of
                personal data. Please read the following carefully to understand
                our views and practices regarding your personal data..
              </p>
              <p>
                Downloading, accessing, or otherwise using the App indicates
                that you have read this Privacy Policy and consent to its terms.
                If you do not consent to the terms of this Privacy Policy, do
                not proceed to download, access, or otherwise use the App.
              </p>
              <p>
                We collect your personal information in order to provide and
                continually improve our products and services.
              </p>
              <p>
                Our privacy policy is subject to change at any time without
                notice. To make sure you are aware of any changes, please review
                this policy periodically. The last updated date can be found at
                the beginning of this policy.
              </p>
              <p>
                All partner firms and any third-party working with or for Us,
                and who have access to personal information, will be expected to
                read and comply with this policy.
              </p>
              <h4>HOW WE COLLECT THE INFORMATION</h4>
              <p>
                From you directly and through this App: We may collect
                information through the App when you visit. The data we collect
                depends on the context of your interactions with our App.
              </p>
              <p>
                Through business interaction: We may collect information through
                business interaction with you or your employees.
              </p>
              <p>
                From other sources: We may receive information from other
                sources, such as public databases; joint marketing partners;
                social media platforms; or other third parties such as:
              </p>

              <p>
                Updated delivery and address information from our carriers or
                other third parties, which we use to correct our records and
                deliver your next purchase or communication more easily.
              </p>
              <p>
                Information about your interactions with the products and
                services offered by our subsidiaries.
              </p>

              <h4>INFORMATION WE COLLECT</h4>
              <p>
                We collect information primarily to provide better services to
                all of our customers.
              </p>
              <p>
                We collect the following information from you when you use or
                signup on our App: Name, Email address, Phone no, Identify
                proof, Hospital Details
              </p>
              <p>
                We do collect the following sensitive information when you use
                or signup on our App: Health data of the Neonate and Mother
                related to Sepsis
              </p>
              <p>
                When you visit our App, some information is automatically
                collected. This may include information such as the type of
                mobile device, Operating System (OS) running on your device,
                Internet Protocol (IP) address, unique user ID, access times,
                device type, and language. We also collect information about how
                you use Our products or services.
              </p>
              <p>
                We automatically collect purchase or content use history, which
                we sometimes aggregate with similar information from other
                customers to create features such as Best Seller, Top Rated,
                etc...
              </p>
              <p>
                The information about your usage of the App, including crash
                logs and usage statistics.
              </p>
              <p>
                We will retain your information as long as we require this to
                provide you with the goods and services and for such period as
                mandated by the laws concerned.
              </p>
              <p>
                If you opt to receive marketing correspondence from us,
                subscribe to our mailing list or newsletters, enter into any of
                our competitions or provide us with your details at networking
                events, we may use your personal data for our legitimate
                interests in order to provide you with details about our goods,
                services, business updates and events.
              </p>

              <h4>HOW WE USE INFORMATION</h4>
              <p>
                We use the information we collect primarily to provide,
                maintain, protect, and improve our current products and
                services.
              </p>

              <p>
                We use the information collected through this App as described
                in this policy and we may use your information to:
              </p>
              <p>
                Improve our services, App and how we operate our businesses;
              </p>
              <p>
                Understand and enhance your experience using our App, products
                and services;
              </p>
              <p>
                {" "}
                Personalize our products or services and make recommendations;
              </p>
              <p> Provide and deliver products and services you request;</p>
              <p>Process, manage, complete, and account for transactions;</p>
              <p>
                Provide customer support and respond to your requests, comments,
                and inquiries;
              </p>
              <p>
                Create and manage the online accounts you manage on our App;
              </p>
              <p>
                {" "}
                Send you related information, including confirmations, invoices,
                technical notices, updates, security alerts and support and
                administrative messages;
              </p>
              <p>
                Communicate with you about promotions, upcoming events, and news
                about products and services;
              </p>
              <p>
                We may process your personal information without your knowledge
                or consent where required by applicable law or regulation for
                the purposes of verification of identity or for prevention,
                detection, or investigation, including of cyber incidents,
                prosecution, and punishment of offences;
              </p>
              <p>
                {" "}
                Protect, investigate, and deter against fraudulent,
                unauthorized, or illegal activity.
              </p>

              <h4>DATA TRANSFER</h4>
              <p>
                Information about our users is an important part of our business
                and we take due care.
              </p>
              <p>
                We share your data with your consent or to complete any
                transaction or provide any product or service you have requested
                or authorized. We also share data with our affiliates and
                subsidiaries, with vendors working on our behalf.
              </p>
              <p>
                We may employ other companies and individuals to perform
                functions on our behalf. The functions include fulfilling orders
                for products or services, delivering packages, sending postal
                mail and e-mail, removing repetitive information from customer
                lists, providing marketing assistance, providing search results
                and links (including paid listings and links), processing
                payments, transmitting content, scoring credit risk, and
                providing customer service.
              </p>

              <p>
                These third-party service providers have access to personal
                information needed to perform their functions but may not use it
                for other purposes. Further, they must process the personal
                information in accordance with this Privacy Policy and as
                permitted by applicable data protection laws.
              </p>
              <p>
                We release accounts and other personal information when we
                believe it is appropriate to comply with the law, enforce or
                apply our conditions of use, and other agreements, protect the
                rights, property or safety of Us, our users, or others. This
                includes exchanging information with other companies and
                organizations for fraud protection and credit risk reduction.
              </p>

              <h4> DATA SECURITY</h4>
              <p>
                We take due care to protect customer data. Technical measures
                are in place to prevent unauthorized or unlawful access to data
                and against accidental loss or destruction of, or damage to the
                data. The employees who are dealing with the data have been
                trained to protect the data from any illegal or unauthorized
                usage.
              </p>
              <p>
                We maintain physical, electronic, and procedural safeguards in
                connection with the collection, storage, and disclosure of
                personal customer information.
              </p>
              <p>
                We take reasonable steps to help protect your personal
                information in an effort to prevent the loss, misuse, and
                unauthorized access, disclosure alteration and destruction. It
                is your responsibility to protect your user names and passwords
                to help prevent anyone from accessing or abusing your accounts
                and services. You should not use or reuse the same passwords you
                use with other accounts as your password for our services.
              </p>
              <p>
                It is important for you to protect against unauthorized access
                to your password and your devices, and applications. Be sure to
                sign off when you finish using a non-personal device.
              </p>
              <p>
                The information you provide to us is shared on our secure
                servers. We have implemented appropriate physical, technical and
                organizational measures designed to secure your information
                against accidental loss and unauthorized access, use,
                alteration, or disclosure. In addition, we limit access to
                personal data to those employees, agents, contractors, and other
                third parties that have a legitimate business need for such
                access.
              </p>
              <p>
                Information collected from you will be stored for such period as
                required to complete the transaction entered into with you or
                such period as mandated under the applicable laws.
              </p>

              <h4>LINKS TO THIRD PARTY SITE/APPS</h4>
              <p>
                Our App may, from time to time, contain links to and from other
                Apps/Websites of third parties. Please note that if you follow a
                link to any of these Apps/Websites, such Apps/Websites will
                apply different terms to the collection and privacy of your
                personal data, and we do not accept any responsibility or
                liability for these policies. When you leave our Site, we
                encourage you to read the privacy policy of every App/Website
                you visit.
              </p>

              <h4>SHARING OF PERSONAL INFORMATION</h4>
              <p>
                We do not share your personal data with third parties without
                your prior consent other than:
              </p>
              <p>
                With third parties who work on our behalf provided such third
                parties adhere to the data protection principles set out in the
                IT Act, 2000 (21 of 2000) and other applicable legislation, or
                enter into a written agreement with Us requiring that the third
                party provide at least the same level of privacy protection as
                is required by such principles;
              </p>
              <p>
                To comply with laws or to respond to lawful requests and legal
                process;
              </p>
              <p>
                To protect the rights and property of Us, our agents, customers,
                and others including to enforce our agreements, policies and
                terms of use;
              </p>
              <p>
                In an emergency, including to protect the personal safety of any
                person; and
              </p>
              <p>
                For the purpose of a business deal (or negotiation of a business
                deal) involving the sale or transfer of all or a part of our
                business or assets (business deals may include, for example, any
                merger, financing, acquisition, divestiture or bankruptcy
                transaction or proceeding).
              </p>

              <h4>CHILDREN</h4>
              <p>
                If you are under the age of 18 or the age of majority in the
                jurisdiction in which you reside, you may only use Our App with
                the consent of your parent or legal guardian. In any case, We
                will not be liable for any cause of action that arose due to
                non-compliance with this section.
              </p>

              <h4>YOUR INFORMATION CHOICES AND CHANGES</h4>
              <p>
                You can also make choices about the collection and processing of
                your data by Us. You can access your personal data and opt-out
                of certain services provided by the Us. In some cases, your
                ability to control and access to your data will be subject to
                applicable laws.
              </p>
              <p>
                You may opt-out of receiving promotional emails from Us by
                following the instructions in those emails. If you opt-out, we
                may still send you non- promotional emails, such as emails about
                our ongoing business relationship. You may also send requests
                about you got preferences, changes and deletions to your
                information including requests to opt-out of sharing your
                personal information with third parties by sending an email to
                the email address provided at the bottom of this document.
              </p>

              <h4>CHANGES TO THIS POLICY</h4>
              <p>
                We may change this policy from time to time. If we make any
                changes to this policy, we will change the &quot;Last
                Updated&quot; date above. You agree that your continued use of
                our services after such changes have been published to our
                services will constitute your acceptance of such revised policy.
              </p>
              <p>
                If you have any concern about privacy or grievances with Us,
                please contact us with a thorough description and we will try to
                resolve the issue for you.
              </p>

              <h4>Contact Details:</h4>
              <p>
                Managing Director, Avyantra Health Technologies Pvt Ltd,
                Hyderabad. mail : avyantra@gmail.com
              </p>
              <p>
                I agree to the Application Disclaimer &amp; Privacy Policy
                mentioned as above.
              </p>

              {/*  <div className="formSection"> */}
              <Grid container>
                <Grid className="continue">
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => AcceptDisclaimer()}
                      color="primary"
                    >
                      <span>ACCEPT</span>
                    </Button>
                  </Grid>
                </Grid>

                <Grid className="continue">
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      className="purple-add"
                      onClick={() => RejectDisclaimer()}
                    >
                      <span>REJECT</span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* </div> */}
          </DialogContent>
        </Dialog>

        {patient.errorMessage && (
          <Snackbar
            open={patient.errorMessage}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" variant="filled">
              {patient.errorMessage}
            </Alert>
          </Snackbar>
        )}
        {/* {signup.showSubScription && <SubscPopUp />} */}
      </ThemeProvider>
    </React.Fragment>
  );
};

export default AccCreation;
