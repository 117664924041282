import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  IconButton,
} from "@mui/material";
import DeleteRec from "./DeleteRec";
import AddIcon from "@mui/icons-material/Add";

import { Link } from "react-router-dom";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import Accept from "../../common/assets/images/Accept.svg";
import Pending from "../../common/assets/images/Pending.svg";
import Pending1 from "../../common/assets/images/Pending1.svg";
import { DataGrid } from "@mui/x-data-grid";
import DeleteImg from "../../common/assets/images/components/Deleteimg";
import AdminEditImg from "../../common/assets/images/components/admineditsvg";
import Stack from "@mui/material/Stack";

import "../../dataEntry/styles/DataEntry.scss";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../reducers/branchDataThunk";
import {
  getRefDoctorsFromTable,
  updateReferalDoctorDetails,
  addReferralDoctorToTable,
  resendVerification,
  setReferalDoctorFormData,
  activateReferalDr,
} from "../reducers/wardThunk";
const ALPHA = /^[a-zA-Z ]+$/;
const Numerics = /^[0-9 ]*$/;
const testEmail = /^[A-Z0-9._!&%$#@+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
import { ToastContainer, toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import { CustomPagination } from "../../Dashboard/components/DashboardScreen";
const ReferralDoctor = (props) => {
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch);
  const RefDrData = useSelector((state) => state.ward);
  const [addRefDoctorData, setAddRefDoctorData] = useState(
    RefDrData.RefDoctorData
  );
  const [RefDoctorsList, setRefDoctors] = useState([]);
  const [filteredRefDoctors, setFilteredRefDoctors] = useState([]);

  const [deleteselrec, setDelete] = React.useState(false);
  const [SelectedDoctor, setSelectedDoctor] = useState([]);
  const [PendingDoctor, setPendingDoctor] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [pendingStatus, setPendingStatus] = React.useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState();
  const [showCityErrorMessage, setShowCityErrorMessage] = useState();
  const [selectedStatus, setSelectedStatus] = React.useState(-1);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  const hospital_id = user.userProfile.hospital_id;
  const doctor_name = RefDoctorsList?.doctor_name;
  useEffect(() => {
    getReferralDoctors();
  }, [hospital_id]);

  /*   useEffect(() => {
    setRefDoctors(RefDrData?.RefDoctorsList);
  }, [RefDrData?.RefDoctorsList]); */

  const getReferralDoctors = () => {
    const hospital_id = user.userProfile?.hospital_id;
    if (hospital_id) {
      dispatch(getRefDoctorsFromTable(hospital_id))
        .unwrap()
        .then((resdata) => {
          setRefDoctors(resdata.response);
        });
    }
  };
  /*  useEffect(() => {
    dispatch(getRefDoctorsFromTable(hospital_id))
      .unwrap()
      .then((resdata) => {
        setRefDoctors(resdata.response);
      });
  }, []); */

  const Deletereading = (params) => {
    setSelectedDoctor(params.row);
    setDelete(true);
  };
  const closeDialog = () => {
    getReferralDoctors();
    setDelete(false);
  };

  useEffect(() => {
    setAddRefDoctorData(RefDrData?.RefDoctorData);
  }, [RefDrData?.RefDoctorData]);

  useEffect(() => {
    updateFilteredRefDrs(selectedStatus);
  }, [RefDoctorsList]);

  const updateFilteredRefDrs = (status) => {
    if (status == -1) {
      setFilteredRefDoctors([].concat(RefDoctorsList));
    } else {
      setFilteredRefDoctors(
        RefDoctorsList.filter((RefDrs) => RefDrs.deleted_flag == status)
      );
    }
  };

  const filterByStatus = (status) => {
    setSelectedStatus(status);
    updateFilteredRefDrs(status);
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;
    const newFormData = { ...addRefDoctorData };
    newFormData[fieldname] = fieldvalue;
    setAddRefDoctorData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    //const validData = await verifyData(); */
    //if (validData) {
    const newFormData = { ...addRefDoctorData };
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    if (!addRefDoctorData.referral_id) {
      newFormData.hospital_id = hospital_id;
      const req = newFormData;
      dispatch(addReferralDoctorToTable(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            dispatch(getRefDoctorsFromTable(hospital_id))
              .unwrap()
              .then((resdata) => {
                setRefDoctors(resdata.response);
              });
            toast.success("Referral Doctor Created Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
    } else if (addRefDoctorData.referral_id) {
      const referral_id = addRefDoctorData.referral_id;
      const req = { referral_id, hospital_id, newFormData };
      dispatch(updateReferalDoctorDetails(req))
        .unwrap()
        .then((resdata) => {
          if (resdata.status == "200") {
            setAddRefDoctorData({});
            dispatch(getRefDoctorsFromTable(hospital_id))
              .unwrap()
              .then((resdata) => {
                setRefDoctors(resdata.response);
              });

            toast.success("Referral Doctor Updated Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setsuccessMessage(resdata.message));
            setOpen(false);
          } else {
            toast.error(resdata.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            //dispatch(setErrorMessage(resdata.message));
          }
        });
      //}
    }
  };

  const activateReferalDrRecord = (params) => {
    dispatch(activateReferalDr(params.row.referral_id)).then(() => {
      toast.success("Referal Doctor Activated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(getRefDoctorsFromTable(hospital_id))
        .unwrap()
        .then((resdata) => {
          setRefDoctors(resdata.response);
        });
    });
  };

  const columns = [
    {
      field: "doctor_name",
      headerName: <span className="gridheader_font">Name</span>,
      width: 225,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.doctor_name}</span>
      ),
    },
    {
      field: "email_address",
      headerName: <span className="gridheader_font">Email ID</span>,
      width: 275,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.email_address}</span>
      ),
    },
    {
      field: "contact_number",
      headerName: <span className="gridheader_font">Contact Number</span>,
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <span className="dadata">{params.row.contact_number}</span>
      ),
    },
    {
      field: "city",
      headerName: <span className="gridheader_font">City</span>,
      width: 200,
      flex: 1,
      renderCell: (params) => <span className="dadata">{params.row.city}</span>,
    },
    {
      field: "deleted_flag",
      headerName: <span className="gridheader_font">Status</span>,
      width: 150,
      flex: 0.5,
      renderCell: (params) => (
        <span className="dadata">
          {params.row.deleted_flag ? "Deleted" : "Active"}
        </span>
      ),
    },
    {
      field: "verified",
      headerName: <span className="gridheader_font">Verify</span>,
      flex: 0.5,
      width: 150,
      renderCell: (params) => (
        <span className="dadata">
          {params.row.verified ? (
            <span>
              <img src={Accept} />
              <span style={{ padding: "0px 3px" }}>Verified</span>
            </span>
          ) : (
            <span className="dadata">
              <img
                src={Pending}
                style={{ padding: "0px 3px", cursor: "pointer" }}
                onClick={(e) => handleClickPending(e, params)}
              />
              Pending
            </span>
          )}
        </span>
      ),
    },
    {
      field: "Edit",
      headerName: <span className="gridheader_font">Actions</span>,
      width: 100,
      flex: 2,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: 10,
              background: "#6572E4",
              //background: "#F98F60",
              borderRadius: 4,
              fontSize: 10,
              boxShadow: "none",
              textTransform: "none",
            }}
            className="view_Reports"
            onClick={() => handleOpen(params)}
          >
            Edit
          </Button>

          {params.row.deleted_flag ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                //background: "#F98F60",

                background: "#008000",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => activateReferalDrRecord(params)}
            >
              Activate
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 10,
                background: "#FF6347",
                borderRadius: 4,
                fontSize: 10,
                boxShadow: "none",
                textTransform: "none",
              }}
              className="view_Reports"
              onClick={() => Deletereading(params)}
            >
              Delete
            </Button>
          )}
        </strong>
      ),
    },
  ];

  const handleOpen = (params) => {
    const data = RefDoctorsList.filter(
      (d) => d.referral_id === params.row.referral_id
    );
    setAddRefDoctorData(data[0]);
    dispatch(setReferalDoctorFormData(data[0]));
    setOpen(true);
  };

  const handleClickOpen = () => {
    setAddRefDoctorData();
    dispatch(setReferalDoctorFormData());
    setOpen(true);
  };
  const handleClickPending = (e, params) => {
    setPendingDoctor(params.row);
    setPendingStatus(true);
  };
  const handleClose1 = () => {
    setPendingStatus(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const ResendMail = () => {
    const referral_id = PendingDoctor?.referral_id;
    const req = { referralId: referral_id };
    dispatch(resendVerification(req))
      .unwrap()
      .then((resdata) => {
        dispatch(setsuccessMessage(resdata.message));
        setPendingStatus(false);
      });
  };

  useEffect(() => {
    if (branch.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [branch.errorMessage]);

  useEffect(() => {
    if (branch.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [branch.successMessage]);

  const verifyContact = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage(
        "Mobile Number Should be 10 Digits & It Should From 6 to 9"
      );
      setErrorFields(Object.assign({}, errorFields, { contact_number: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { contact_number: false }));
      setShowMobileErrorMessage("");
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="dataoutlet">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card variant="none">
              <CardContent>
                <form id="score-generation">
                  <Grid
                    container
                    style={{
                      borderBottom: "0.8px solid rgba(101, 114, 228, 0.4)",
                    }}
                  >
                    <Grid item xs={12} sm={7} className="header">
                      <h4 className="branch-header">
                        List Of Referral Doctors
                      </h4>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <label
                        htmlFor="Sort"
                        className="dataentry_label"
                        style={{
                          display: "block",
                          fontSize: "16px",
                          padding: "0px 20px",
                          marginTop: "0px",
                        }}
                      >
                        Status
                        <Select
                          labelId="Role"
                          id="status"
                          name="status"
                          label=""
                          size="small"
                          className="select_usertype"
                          value={selectedStatus}
                          onChange={(event) => {
                            filterByStatus(event.target.value);
                          }}
                        >
                          <MenuItem value="-1">All</MenuItem>
                          <MenuItem value="0">Active</MenuItem>
                          <MenuItem value="1">Deleted</MenuItem>
                        </Select>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={2} className="header">
                      <Button
                        variant="outlined"
                        onClick={handleClickOpen}
                        className="branch_Add"
                      >
                        + Add Referral Doctor
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={12} className="header">
                      <div style={{ height: 631, width: "100%" }}>
                        <DataGrid
                          rows={filteredRefDoctors}
                          columns={columns}
                          pageSize={10}
                          rowsPerPageOptions={[10]}
                          showCellRightBorder={true}
                          showColumnRightBorder={true}
                          getRowId={(row) => row.referral_id}
                          components={{
                            Footer: CustomPagination,
                            NoRowsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Referral Doctors Found
                              </Stack>
                            ),
                            NoResultsOverlay: () => (
                              <Stack
                                height="100%"
                                alignItems="center"
                                justifyContent="center"
                              >
                                No Referral Doctors found
                              </Stack>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={pendingStatus}
          onClose={handleClose1}
          className="dialogWidth"
        >
          <DialogTitle
            style={{
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <Link className="FIle-right-extreme" onClick={handleClose1}>
              <img src={Close} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent style={{ margin: "auto" }}>
            <div className="formSection" style={{ margin: "auto" }}>
              <img
                src={Pending1}
                style={{ padding: "0px 3px", width: "40%" }}
              />
              <p>Referral Doctor Action Pending </p>
              <p className="subsc_content">
                Dear Dr You are not accepted the invite. Please try sending the
                email again.
              </p>
              <Grid container>
                <Grid item xs={12} sm={10}>
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="submit"
                    onClick={ResendMail}
                  >
                    <span>Resend Email</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={open} onClose={handleClose} className="dialogWidth">
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "0.8px solid",
              borderColor: "rgba(101, 114, 228, 0.4)",
            }}
          >
            <span>
              <Link
                className="FIle-left"
                onClick={handleClose}
                style={{ marginRight: "20px" }}
              >
                <img
                  src={Back}
                  className="file-img-left"
                  alt=""
                  style={{ width: "12px" }}
                />
              </Link>
              {!addRefDoctorData?.referral_id ? "Add" : "Update"} Referral
              Doctor
            </span>
            <Link className="FIle-right-extreme" onClick={handleClose}>
              <img src={Close} style={{ marginRight: "20px" }} alt="" />
            </Link>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Name"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Name
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="doctor_name"
                    name="doctor_name"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    required
                    inputProps={{
                      maxLength: 30,
                      minlength: 3,
                    }}
                    onChange={handleAddFormChange}
                    value={
                      addRefDoctorData?.doctor_name
                        ? addRefDoctorData?.doctor_name
                        : ""
                    }
                    error={errorFields.doctor_name && showNameErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showNameErrorMessage}
                    helperText={
                      errorFields.doctor_name && showNameErrorMessage
                        ? showNameErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!ALPHA.test(event.key)) {
                        setShowNameErrorMessage(
                          "Only Alphabets and Space are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            doctor_name: true,
                          })
                        );
                        event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            contact_person: false,
                          })
                        );
                        setShowNameErrorMessage("");
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Email ID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Email Id
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="email_address"
                    name="email_address"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    type="email"
                    required
                    onChange={handleAddFormChange}
                    value={
                      addRefDoctorData?.email_address &&
                      addRefDoctorData?.email_address
                    }
                    error={errorFields.email_address && showEmailErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showEmailErrorMessage}
                    helperText={
                      errorFields.email_address && showEmailErrorMessage
                        ? showEmailErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!testEmail.test(event.target.value)) {
                        setShowEmailErrorMessage("Please Enter Valid Email Id");
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            email_address: true,
                          })
                        );

                        //event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            email_address: false,
                          })
                        );
                        setShowEmailErrorMessage("");
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="Phone Number"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Phone Number
                    <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="Contact"
                    name="contact_number"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    /* className={
                      "dataentry_input " +
                      (errorFields.contact_number ? "errorField" : "")
                    } */
                    required
                    size="small"
                    value={addRefDoctorData?.contact_number}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyContact(e);
                    }}
                    error={errorFields.contact_number && showMobileErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showMobileErrorMessage}
                    helperText={
                      errorFields.contact_number && showMobileErrorMessage
                        ? showMobileErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowMobileErrorMessage(
                          "Only Numerics are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            contact_number: true,
                          })
                        );
                      }
                    }}
                    //onBlur={verifyContact}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="City"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    City
                  </label>
                  <TextField
                    id="city"
                    name="city"
                    label=""
                    variant="outlined"
                    className="dataentry_input"
                    size="small"
                    onChange={handleAddFormChange}
                    value={addRefDoctorData?.city ? addRefDoctorData?.city : ""}
                    error={errorFields.city && showCityErrorMessage}
                    //helperText={showErrorMessage}
                    errorText={showCityErrorMessage}
                    helperText={
                      errorFields.city && showCityErrorMessage
                        ? showCityErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (!ALPHA.test(event.key)) {
                        setShowCityErrorMessage(
                          "Only Alphabets and Space are acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, { city: true })
                        );
                        event.preventDefault();
                      } else {
                        handleAddFormChange(event);
                        setErrorFields(
                          Object.assign({}, errorFields, { city: false })
                        );
                        setShowCityErrorMessage("");
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <div className="formSection">
                <Grid
                  container
                  xs={12}
                  sm={12}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      className="back"
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!addRefDoctorData?.referral_id ? (
                      <div>
                        {isValid ? (
                          <Button
                            variant="contained"
                            className="purple-add"
                            type="submit"
                            onClick={handleClick({
                              vertical: "top",
                              horizontal: "center",
                            })}
                          >
                            <span>Add Doctor</span>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="back1"
                            disabled
                          >
                            <span>Add Doctor</span>
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div>
                        {isValid ? (
                          <Button
                            variant="contained"
                            className="purple-add"
                            type="submit"
                            style={{ backgroundColor: "gray" }}
                            onClick={handleClick({
                              vertical: "top",
                              horizontal: "center",
                            })}
                          >
                            <span>Update Doctor</span>
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            className="back1"
                            disabled
                          >
                            <span>Update Doctor</span>
                          </Button>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      {branch.errorMessage && (
        <Snackbar
          open={branch.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {branch.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {branch.successMessage && (
        <Snackbar
          open={branch.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {branch.successMessage}
          </Alert>
        </Snackbar>
      )}

      {deleteselrec && (
        <DeleteRec
          SelectedDoctor={SelectedDoctor}
          open={deleteselrec}
          onClose={closeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default ReferralDoctor;
