import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  FormGroup,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer, toast } from "react-toastify";
const Numerics = /^[0-9 ]*$/;

import "../styles/DataEntry.scss";
import {
  URINE_OUTPUT,
  CAPILLARY_REFILL,
  CENTRAL_LINE_VALUE,
} from "../utils/mockData";

import {
  babyCvAddition,
  updatePatientFormData,
  setBabyCVData,
  getBabyCVFByReadingId,
  setFormTab,
  getPatientLastReadingData,
  getPatientBasicDetails,
} from "../reducers/patientDataThunk";
import CircularProgress from "@mui/material/CircularProgress";

const BabyCardioVascularFunction = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const ptbmrn = patient.bmrndata?.baby_medical_record_number;
  const [dischargeFlag, setDischargeFlag] = useState("1");
  const loggedUserId = user?.userProfile?.user_id;
  const [CentralLineInsertDate, setCentralLineInsertDate] = React.useState(
    moment().format()
  );
  const [CentralLineRemovedDate, setCentralLineRemovedDate] = React.useState(
    moment().format()
  );
  const baby_date_of_admission = patient.getBabyProfile?.baby_date_of_admission;
  const [addBabyCVData, setAddBabyCVData] = useState(patient.babyCVData);

  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [showHeartRateErrorMessage, setShowHeartRateErrorMessage] = useState();
  const [showBPMAErrorMessage, setShowBPMAErrorMessage] = useState();
  const [showBPLLErrorMessage, setShowBPLLErrorMessage] = useState();
  const [showBPULErrorMessage, setShowBPULErrorMessage] = useState();
  const [show2DEchoErrorMessage, setShow2DEchoErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };
  useEffect(() => {
    const req = { baby_medical_record_number: ptbmrn };
    dispatch(getPatientBasicDetails(req))
      .unwrap()
      .then((resdata) => {
        const disFlag = resdata.response.discharge_flag;
        setDischargeFlag(disFlag);
      });
  }, [ptbmrn]);
  let url = `patient/update/baby_cv`;
  //let reading = patient.globalRecord?.reading_id;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setAddBabyCVData(patient.babyCVData);
  }, [patient.babyCVData]);

  useEffect(() => {
    if (readingId) {
      updateData();
    }
  }, [readingId]);

  const updateData = (event) => {
    const req = { readingId, studyid };
    dispatch(getBabyCVFByReadingId(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          let CVData = {
            response: Object.assign({}, resdata.response),
          };
          const data =
            CVData.response && CVData.response ? CVData.response : undefined;
          const data1 =
            CVData.response && CVData.response.id
              ? setUpdateRecord(true)
              : setUpdateRecord(false);

          if (resdata.response) {
            setAddBabyCVData(
              CVData.response && CVData.response ? CVData.response : {}
            );
          }
        } else {
          const study_id = patient?.bmrndata?.study_id;
          //const req = { studyid };
          dispatch(getPatientLastReadingData(study_id))
            .unwrap()
            .unwrap()
            .then((resdata) => {
              if (resdata.response) {
                let CVData = {
                  response: Object.assign({}, resdata.response.baby_cv),
                };
                const data =
                  CVData.response && CVData.response
                    ? CVData.response
                    : undefined;
                const data1 =
                  CVData.response && CVData.response.id
                    ? setUpdateRecord(false)
                    : setUpdateRecord(false);

                if (resdata.response) {
                  setAddBabyCVData(
                    CVData.response && CVData.response ? CVData.response : {}
                  );
                }
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);

    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);

    const newFormData = { ...addBabyCVData };
    newFormData[fieldname] = fieldvalue;

    // console.log("Field Value is ");
    // console.log(newFormData);

    setAddBabyCVData(newFormData);
    setBabyCVData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(6);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addBabyCVData };
    // console.log("Data That the Application is sending is,");
    // console.log(newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;

    if (!updateRecord) {
      dispatch(babyCvAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyCVFByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // console.log("Inside baby CV update");
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyCVFByReadingId(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyHeartRate = (event) => {
    if (event.target.value >= 1 && event.target.value <= 250) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: false,
        })
      );
      setShowHeartRateErrorMessage("");
    } else {
      setShowHeartRateErrorMessage(
        "Heart Rate Value Should be Between 1 - 250"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          heart_rate: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureUpperLimb = (event) => {
    if (event.target.value > 1 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: false,
        })
      );
      setShowBPULErrorMessage("");
    } else {
      setShowBPULErrorMessage("BP (Systolic) Value Should be Between 1 - 200");
      //document.getElementById("BabyBloodPressureUpperLimb").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_upper_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureLowerLimb = (event) => {
    if (event.target.value > 1 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: false,
        })
      );
      setShowBPLLErrorMessage("");
    } else {
      setShowBPLLErrorMessage("BP (Diastolic) Value Should be Between 1 - 200");
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_lower_limb: true,
        })
      );
    }
  };

  const verifyBabyBloodPressureMeanArterial = (event) => {
    if (event.target.value > 0 && event.target.value <= 200) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: false,
        })
      );
      setShowBPMAErrorMessage("");
    } else {
      setShowBPMAErrorMessage(
        "BP (Mean Arterial) Value Should be Between 1 To 200"
      );
      //document.getElementById("BabyBloodPressureMeanArterial").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_blood_pressure_mean_arterial_bp: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      {isLoading ? (
        <div
          style={{
            height: "300px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Card variant="none">
            <CardContent>
              <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="PatientRecordID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Record Id
                    </label>
                    <TextField
                      id="study_id"
                      name="study_id"
                      label=""
                      variant="outlined"
                      value={patient.bmrndata?.study_id}
                      onChange={handleAddFormChange}
                      className="dataentry_input"
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="HeartRate"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Heart Rate
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <FormGroup row>
                      <TextField
                        id="HeartRate"
                        label=""
                        name="heart_rate"
                        variant="outlined"
                        size="small"
                        disabled={dischargeFlag == "0"}
                        //required
                        className={
                          "dataentry_input " +
                          (errorFields.heart_rate ? "errorField" : "")
                        }
                        //disabled={!defaultCheck.HeartRateChecked}
                        value={addBabyCVData?.heart_rate}
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyHeartRate(e);
                        }}
                        error={
                          errorFields.heart_rate && showHeartRateErrorMessage
                        }
                        errorText={showHeartRateErrorMessage}
                        helperText={
                          errorFields.heart_rate && showHeartRateErrorMessage
                            ? showHeartRateErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowHeartRateErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                heart_rate: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 3,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              bpm
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="UrineOutput"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Urine Output
                    </label>
                    <Select
                      labelId="UrineOutput"
                      id="urine_output"
                      name="urine_output"
                      label=""
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addBabyCVData?.urine_output}
                      disabled={dischargeFlag == "0"}
                    >
                      {URINE_OUTPUT.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="BPSystolic"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      BP (Systolic)
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <FormGroup row>
                      <TextField
                        id="BabyBloodPressureUpperLimb"
                        label=""
                        name="baby_blood_pressure_upper_limb"
                        variant="outlined"
                        size="small"
                        disabled={dischargeFlag == "0"}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_blood_pressure_upper_limb
                            ? "errorField"
                            : "")
                        }
                        //disabled={!defaultCheck.BPSystolicChecked}
                        value={addBabyCVData?.baby_blood_pressure_upper_limb}
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBloodPressureUpperLimb(e);
                        }}
                        error={
                          errorFields.baby_blood_pressure_upper_limb &&
                          showBPULErrorMessage
                        }
                        errorText={showBPULErrorMessage}
                        helperText={
                          errorFields.baby_blood_pressure_upper_limb &&
                          showBPULErrorMessage
                            ? showBPULErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBPULErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_blood_pressure_upper_limb: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          
                      inputProps: {
                        maxLength: 3,
                      },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              mmHg
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="BPDiastolic"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      BP (Diastolic)
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <FormGroup row>
                      <TextField
                        id="BabyBloodPressureLowerLimb"
                        label=""
                        name="baby_blood_pressure_lower_limb"
                        variant="outlined"
                        size="small"
                        disabled={dischargeFlag == "0"}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_blood_pressure_lower_limb
                            ? "errorField"
                            : "")
                        }
                        //disabled={!defaultCheck.BPDiastolicChecked}
                        value={addBabyCVData?.baby_blood_pressure_lower_limb}
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBloodPressureLowerLimb(e);
                        }}
                        error={
                          errorFields.baby_blood_pressure_lower_limb &&
                          showBPLLErrorMessage
                        }
                        errorText={showBPLLErrorMessage}
                        helperText={
                          errorFields.baby_blood_pressure_lower_limb &&
                          showBPLLErrorMessage
                            ? showBPLLErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBPLLErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_blood_pressure_lower_limb: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 3,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              mmHg
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="BPMeanArterial"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      BP (Mean Arterial)
                      {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <FormGroup row>
                      <TextField
                        id="BabyBloodPressureMeanArterial"
                        label=""
                        name="baby_blood_pressure_mean_arterial_bp"
                        variant="outlined"
                        size="small"
                        disabled={dischargeFlag == "0"}
                        className={
                          "dataentry_input " +
                          (errorFields.baby_blood_pressure_mean_arterial_bp
                            ? "errorField"
                            : "")
                        }
                        value={
                          addBabyCVData?.baby_blood_pressure_mean_arterial_bp
                        }
                        onChange={(e) => {
                          handleAddFormChange(e);
                          verifyBabyBloodPressureMeanArterial(e);
                        }}
                        error={
                          errorFields.baby_blood_pressure_mean_arterial_bp &&
                          showBPMAErrorMessage
                        }
                        errorText={showBPMAErrorMessage}
                        helperText={
                          errorFields.baby_blood_pressure_mean_arterial_bp &&
                          showBPMAErrorMessage
                            ? showBPMAErrorMessage
                            : ""
                        }
                        onKeyDown={(event) => {
                          if (
                            !Numerics.test(event.key) &&
                            event.key != "Backspace"
                          ) {
                            event.preventDefault();
                            setShowBPMAErrorMessage(
                              "Only Numerics are acceptable"
                            );
                            setErrorFields(
                              Object.assign({}, errorFields, {
                                baby_blood_pressure_mean_arterial_bp: true,
                              })
                            );
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 3,
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className="InputAdornment"
                            >
                              mmHg
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="CapillaryRefill"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Capillary Refill
                      {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <Select
                      labelId="capillary refill"
                      id="capillary_refill_unit"
                      name="capillary_refill_unit"
                      label=""
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addBabyCVData?.capillary_refill_unit}
                      disabled={dischargeFlag == "0"}
                    >
                      {CAPILLARY_REFILL.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="LowPeripheralPulseVolume"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Low Peripheral Pulse Volume
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.low_peripheral_pulse_volume}
                      exclusive
                      onChange={handleAddFormChange}
                      disabled={dischargeFlag == "0"}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="low_peripheral_pulse_volume"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="low_peripheral_pulse_volume"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="low_peripheral_pulse_volume"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="CoolPeripheries"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Cool Peripheries
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.cool_peripheries}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      disabled={dischargeFlag == "0"}
                    >
                      <ToggleButton
                        name="cool_peripheries"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="cool_peripheries"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="cool_peripheries"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="2DEchoDone"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      2D Echo (Done)
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.two_d_echo_done}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      disabled={dischargeFlag == "0"}
                    >
                      <ToggleButton
                        name="two_d_echo_done"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="two_d_echo_done"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="two_d_echo_done"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {addBabyCVData?.two_d_echo_done == "YES" && (
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="2DEchoResultIfYes"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      2D Echo Result (If Yes)
                    </label>
                    <FormGroup row>
                      <TextField
                        id="two_d_echo_done_if_yes"
                        label=""
                        name="two_d_echo_done_if_yes"
                        variant="outlined"
                        size="small"
                        className="dataentry_input"
                        disabled={dischargeFlag == "0"}
                        value={addBabyCVData?.two_d_echo_done_if_yes}
                        onChange={(e) => {
                          handleAddFormChange(e);
                        }}
                        error={
                          errorFields.two_d_echo_done_if_yes &&
                          show2DEchoErrorMessage
                        }
                        errorText={showBPMAErrorMessage}
                        helperText={
                          errorFields.two_d_echo_done_if_yes &&
                          show2DEchoErrorMessage
                            ? show2DEchoErrorMessage
                            : ""
                        }
                        /*  onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShow2DEchoErrorMessage(
                            "Only Numerics are acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              two_d_echo_done_if_yes: true,
                            })
                          );
                        } else {
                          setShow2DEchoErrorMessage("");
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              two_d_echo_done_if_yes: true,
                            })
                          );
                        }
                      }} */
                      />
                    </FormGroup>
                  </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Ionotropes"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Ionotropes
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.baby_on_ionotropes}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      disabled={dischargeFlag == "0"}
                    >
                      <ToggleButton
                        name="baby_on_ionotropes"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="baby_on_ionotropes"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="baby_on_ionotropes"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="BabyOnCentralLines"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Central Lines(Yes/No/NA)
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.central_line}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      disabled={dischargeFlag == "0"}
                    >
                      <ToggleButton
                        name="central_line"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="central_line"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="central_line"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {addBabyCVData?.central_line == "YES" && (
                    <>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="CentralLineValue"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Central Lines
                    </label>

                    <Select
                      labelId="central_line_value"
                      id="central_line_value"
                      name="central_line_value"
                      label=""
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addBabyCVData?.central_line_value}
                      disabled={dischargeFlag == "0"}
                    >
                      {CENTRAL_LINE_VALUE.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor=" Central Lines Insert Date"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Central Lines(Insert Date)
                    </label>
                    <DatePicker
                      id="central_line_insert_date"
                      name="central_line_insert_date"
                      size="small"
                      className="dataentry_input"
                      inputFormat="DD/MM/YYYY"
                      maxDate={moment().format()}
                      minDate={baby_date_of_admission}
                      value={addBabyCVData?.central_line_insert_date}
                      onChange={(date) => {
                        //addFormData.baby_birth_date = date["$d"];
                        setCentralLineInsertDate(date["$d"]);
                        handleAddFormChange({
                          target: {
                            name: "central_line_insert_date",
                            //value: date["$d"],
                          },
                        });
                      }}
                         autocomplete="off"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="dataentry_input"
                          helperText={null}
                          disabled={dischargeFlag == "0"}
                          onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor=" Central Lines Removed Date"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Central Lines(Removed Date)
                    </label>
                    <DatePicker
                      id="central_line_removed_date"
                      name="central_line_removed_date"
                      size="small"
                      className="dataentry_input"
                      inputFormat="DD/MM/YYYY"
                      maxDate={moment().format()}
                      minDate={baby_date_of_admission}
                      value={addBabyCVData?.central_line_removed_date}
                      disabled={dischargeFlag == "0"}
                         autocomplete="off"
                      onChange={(date) => {
                        setCentralLineRemovedDate(date["$d"]);
                        handleAddFormChange({
                          target: {
                            name: "central_line_removed_date",
                            value: date["$d"],
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="dataentry_input"
                          helperText={null}
                          disabled={dischargeFlag == "0"}
                          onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                        />
                      )}
                    />
                  </Grid>
                  </>
                  )}
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Infusion of Blood Products"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Infusion of Blood Products
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addBabyCVData?.infusion_of_blood_products}
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      disabled={dischargeFlag == "0"}
                    >
                      <ToggleButton
                        name="infusion_of_blood_products"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="infusion_of_blood_products"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="infusion_of_blood_products"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  {isValid ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      aria-label="add"
                      className="buttonStyle sign-up-button"
                      type="submit"
                      disabled={dischargeFlag == "0"}
                      style={{ cursor: "pointer" }}
                    >
                      Save {"&"} Next
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="gray"
                      aria-label="add"
                      className="buttonStyle1 sign-up-button"
                      disabled
                    >
                      Save {"&"} Next
                    </Button>
                  )}
                </div>

                {/*  <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                  >
                    Save {"&"} Next
                  </Button>
                </div> */}
              </form>
            </CardContent>
          </Card>
        </LocalizationProvider>
      )}
    </React.Fragment>
  );
};

export default BabyCardioVascularFunction;
