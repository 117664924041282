import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Snackbar,
  Alert,
  Checkbox,
  ListItemText,
} from "@mui/material";

const ALPHA = /^[a-zA-Z ]*$/;
const Numerics = /^[0-9 ]*$/;
const testEmail = /^[A-Z0-9._!&%$#@+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

import AdminMap from "./AdminMap";
import "../../../dataEntry/styles/DataEntry.scss";
import { ToastContainer, toast } from "react-toastify";
import {
  addAdminDetails,
  getMappedAdminDetailsByUser,
  getAdminDetail,
  getBranchDetail,
  updateAdminDetails,
  getUserRoles,
  mapAdminWithBranch,
  getUserHierarchyDetail,
  getAdminsByRole,
  mapUserHierarchy,
} from "../../reducers/adminThunk";
import {
  setErrorMessage,
  setsuccessMessage,
} from "../../reducers/branchDataThunk";

const AddAdmin = (props) => {
  const admin = useSelector((state) => state.admin);
  const user = useSelector((state) => state.user);
  const branch = useSelector((state) => state.branch);
  const [addAdminData, setAddAdminData] = useState(admin?.admindata);
  const [adminMap, setAdminMap] = useState([]);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;
  const [userRoles, setUserRolesList] = React.useState([]);
  const [showFormdata, setShowForm] = useState(false);
  const [branches, setBranches] = React.useState([]);
  const [selectedBranches, setSelectedBranches] = React.useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [mappedAdmins, setMappedAdmins] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState();
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);
  console.log('jjjjjjjj',addAdminData)

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };

  useEffect(() => {
    dispatch(getUserRoles());
    const req = { hospital_id };
    dispatch(getBranchDetail(req)).then((response) => {
      setBranches(response.payload.response);
    });
    admin?.admindata?.id
      ? dispatch(getMappedAdminDetailsByUser(admin?.admindata?.id)).then(
          (response) => {
            const branchesData = response.payload.response.map(
              (data) => data.branch_id
            );
            setSelectedBranches(branchesData);
          }
        )
      : null;
  }, []);

  useEffect(() => {
    const admin_id = addAdminData?.id;
    const role = addAdminData?.role_id;

    dispatch(getBranchDetail(hospital_id));
    dispatch(getUserHierarchyDetail(admin_id)).then((response) => {
      const admins = [];
      response.payload.response.map((admin) => {
        admins.push(admin.mapped_user_id);
      });
      setSelectedUsers(admins);
    });
  }, []);

  useEffect(() => {
    if (addAdminData?.role_id == "4") {
      const role_id = "7";
      const req = { role_id, hospital_id };
      dispatch(getAdminsByRole(req));
    } else if (addAdminData?.role_id == "7") {
      const role_id = "6";
      const req = { role_id, hospital_id };
      dispatch(getAdminsByRole(req));
    }
  }, [addAdminData?.role_id]);

  useEffect(() => {
    const admin_id = addAdminData?.id;
    const { branchList, UserHierarchyList, adminListByRole } = admin;
    const mapping = [];
    if (adminListByRole && adminListByRole.length > 0) {
      for (const admin of adminListByRole) {
        mapping.push({ ...admin });
      }
    }
    if (selectedUsers && selectedUsers.length) {
      const users = [];
      selectedUsers.map((user) => {
        const index = _.findIndex(mapping, { id: user });
        if (index > -1) {
          users.push(mapping[index]);
        }
      });
      setMappedAdmins(users);
    } else {
      setMappedAdmins([]);
    }
    setAdminMap(mapping);
  }, [admin.UserHierarchyList, admin.adminListByRole, selectedUsers]);

  useEffect(() => {
    if (admin && admin.userRolesList) setUserRolesList(admin.userRolesList);
    else setUserRolesList([]);
  }, [admin.userRolesList]);

  useEffect(() => {
    if (branch.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [branch.errorMessage]);

  useEffect(() => {
    if (branch.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [branch.successMessage]);

  const verifyData = async () => {
    let validData = true;
    if (addAdminData?.contact.length != 10) {
      toast.error("Contact Number Should be 10 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(Object.assign({}, errorFields, { contact: true }));
      validData = false;
    }
    return validData;
  };

  const updateSelectedHierarchy = (event) => {
    setMappedAdmins(event.target.value);
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...addAdminData };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setAddAdminData(newFormData);
  };
  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    const validData = await verifyData();
    if (validData) {
      // console.log("Data That the Application is sending is,");
      // console.log(addAdminData);
      const newFormData = { ...addAdminData };
      // console.log("About to call Axios API");
      for (const key in newFormData) {
        if (!newFormData[key]) {
          newFormData[key] = "NA";
        }
      }

      if (!admin?.admindata?.id) {
        const req = { hospital_id, newFormData };
        dispatch(addAdminDetails(req))
          .unwrap()
          .then((resdata) => {
            if (resdata.error) {
              toast.error(resdata.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              //dispatch(setErrorMessage(resdata.message));
            } else {
              updateUserHierarchy(resdata.response.id);
              setAddAdminData({});
              const req = {
                admin_id: resdata.response.id,
                branch_id: selectedBranches,
              };
              dispatch(mapAdminWithBranch(req));
              toast.success("User Created Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              //dispatch(setsuccessMessage(resdata.message));
              props.closeModal();
            }
          });
      } else if (admin?.admindata?.id) {
        const admin_id = admin?.admindata?.id;
        const req = { admin_id, hospital_id, newFormData };
        dispatch(updateAdminDetails(req))
          .unwrap()
          .then((resdata) => {
            if (resdata.error) {
              toast.error(resdata.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              //dispatch(setErrorMessage(resdata.message));
            } else {
              //dispatch(setsuccessMessage(resdata.message));
              toast.success("User Updated Successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              const req = {
                admin_id: resdata.response.id,
                branch_id: selectedBranches,
              };
              dispatch(mapAdminWithBranch(req));
              updateUserHierarchy(admin?.admindata?.id);
              props.closeModal();
              setAddAdminData({});
            }
          });
      }
    }
  };

  const updateUserHierarchy = (adminId) => {
    const req = {
      admin_id: adminId,
      mapped_user_id: mappedAdmins.map((user) => user.id),
    };
    dispatch(mapUserHierarchy(req)).then((response) => {
      //dispatch(setsuccessMessage(response.payload.message));
      dispatch(getAdminDetail({ hospital_id }));
    });
    setShowForm(!showFormdata);
  };

  const verifyContact = (event) => {
    if (event.target.value.length == "10") {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          contact: false,
        })
      );
    } else {
      toast.error("Contact Number Should be 10 Digits", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(
        Object.assign({}, errorFields, {
          contact: true,
        })
      );
    }
    //}
  };

  const verifyPrimaryContactNo = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage(
        "User Mobile Number Should be 10 Digits & It Should From 6 to 9"
      );
      setErrorFields(Object.assign({}, errorFields, { contact: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { contact: false }));
      setShowMobileErrorMessage("");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <form
        id="mother-medical-form"
        onSubmit={HandleAddFormSubmit}
        style={{ height: "100%" }}
      >
        <Grid
          container
          style={{ overflow: "auto", padding: "20px", paddingBottom: "60px" }}
        >
          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Admin Name"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Name
              <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
            </label>
            <TextField
              id="admin_name"
              name="admin_name"
              label=""
              variant="outlined"
              className="dataentry_input"
              size="small"
              required
              value={addAdminData?.admin_name ? addAdminData?.admin_name : ""}
              onChange={handleAddFormChange}
              error={errorFields.admin_name && showNameErrorMessage}
              //helperText={showErrorMessage}
              errorText={showNameErrorMessage}
              helperText={
                errorFields.admin_name && showNameErrorMessage
                  ? showNameErrorMessage
                  : ""
              }
              onKeyDown={(event) => {
                if (!ALPHA.test(event.key)) {
                  setShowNameErrorMessage(
                    "Only Alphabets and Space are Acceptable"
                  );
                  setErrorFields(
                    Object.assign({}, errorFields, { admin_name: true })
                  );
                  event.preventDefault();
                } else {
                  handleAddFormChange(event);
                  setErrorFields(
                    Object.assign({}, errorFields, { admin_name: false })
                  );
                  setShowNameErrorMessage("");
                }
              }}
              inputProps={{
                maxLength: 30,
                minlength: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Contact Number"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Contact Number
              <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
            </label>
            <TextField
              id="Contact"
              name="contact"
              label=""
              variant="outlined"
              className="dataentry_input"
              /* className={
                "dataentry_input " + (errorFields.contact ? "errorField" : "")
              } */
              size="small"
              value={addAdminData?.contact}
              onChange={(e) => {
                handleAddFormChange(e);
                verifyPrimaryContactNo(e);
              }}
              disabled={admin?.admindata?.id}
              //onChange={handleAddFormChange}
              //onBlur={verifyContact}
              required
              error={errorFields.contact && showMobileErrorMessage}
              //helperText={showErrorMessage}
              errorText={showMobileErrorMessage}
              helperText={
                errorFields.contact && showMobileErrorMessage
                  ? showMobileErrorMessage
                  : ""
              }
              onKeyDown={(event) => {
                if (!Numerics.test(event.key) && event.key != "Backspace") {
                  event.preventDefault();
                  setShowMobileErrorMessage("Only Numerics are Acceptable");
                  setErrorFields(
                    Object.assign({}, errorFields, {
                      contact: true,
                    })
                  );
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Role"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Role
              <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
            </label>
            <Select
              labelId="Role"
              id="role_id"
              name="role_id"
              value={addAdminData?.role_id}
              label=""
              onChange={handleAddFormChange}
              size="small"
              className="dataentry_select_edu"
              required
            >
              {userRoles.map((num) => (
                <MenuItem value={num.role_id}>{num.role_name}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Email ID *"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Email ID
              <span style={{ paddingLeft: "2px", color: "red" }}>*</span>
            </label>
            <TextField
              id="email"
              name="email"
              label=""
              variant="outlined"
              className="dataentry_input"
              size="small"

              disabled={admin?.admindata?.id}
              required
              value={addAdminData?.email}
              onChange={handleAddFormChange}
              type="email"
              error={errorFields.email && showEmailErrorMessage}
              //helperText={showErrorMessage}
              errorText={showEmailErrorMessage}
              helperText={
                errorFields.email && showEmailErrorMessage
                  ? showEmailErrorMessage
                  : ""
              }
              onKeyDown={(event) => {
                if (!testEmail.test(event.target.value)) {
                  setShowEmailErrorMessage("Please Enter Valid Email Id");
                  setErrorFields(
                    Object.assign({}, errorFields, { email: true })
                  );

                  //event.preventDefault();
                } else {
                  handleAddFormChange(event);
                  setErrorFields(
                    Object.assign({}, errorFields, {
                      email: false,
                    })
                  );
                  setShowEmailErrorMessage("");
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Educational Level"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Educational Level
            </label>
            <Select
              labelId="Educational"
              id="educational_level"
              name="educational_level"
              value={addAdminData?.educational_level}
              label=""
              onChange={handleAddFormChange}
              size="small"
              className="dataentry_select_edu"
            >
              <MenuItem value="Primary Education">Primary Education</MenuItem>
              <MenuItem value="Secondary Education/ High School">
                Secondary Education/ High School
              </MenuItem>
              <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
              <MenuItem value="Master's Degree">Master's Degree</MenuItem>
              <MenuItem value="Doctorate or Higher">
                Doctorate or Higher
              </MenuItem>
              <MenuItem value="No Formal Education">
                No Formal Education
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Gender"
              style={{ display: "block" }}
              className="dataentry_label"
            >
              Gender
            </label>
            <ToggleButtonGroup
              color="primary"
              value={addAdminData?.gender}
              exclusive
              onChange={handleAddFormChange}
              className="Toggle_input"
            >
              <ToggleButton
                name="gender"
                value="Male"
                size="small"
                className="dataentry_input"
              >
                Male
              </ToggleButton>
              <ToggleButton
                name="gender"
                value="Female"
                size="small"
                className="dataentry_input"
              >
                Female
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Branch"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              Branches
            </label>
            <Select
              labelId="Branches"
              id="branches"
              name="branches"
              value={selectedBranches}
              label=""
              onChange={(event) => {
                setSelectedBranches(event.target.value);
              }}
              size="small"
              className="dataentry_select_edu"
              multiple
            >
              {branches.map((branch) => (
                <MenuItem value={branch.hospital_branch_id}>
                  {branch.branch_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {addAdminData?.role_id == "4" ||  addAdminData?.role_id == "7" ?(
          <Grid item xs={12} sm={6}>
            <label
              htmlFor="Branch"
              className="dataentry_label"
              style={{ display: "block" }}
            >
              User Hierarchy
            </label>
            <Select
              // labelId="baby_respiratory_support_if_yes"
              // id="baby_respiratory_support_if_yes"
              // className="dataentry_select_edu"
              style={{ width: "90%" }}
              name="wards"
              onChange={updateSelectedHierarchy}
              multiple
              size="small"
              value={mappedAdmins || []}
              disabled={
                addAdminData?.role_id != 4 && addAdminData?.role_id != 7
              }
              renderValue={(selected) => {
                return selected && selected.length
                  ? selected.map((s) => s.admin_name).join(",")
                  : "";
              }}
            >
              {adminMap.map((admin) => (
                <MenuItem key={admin.id} value={admin}>
                  <Checkbox
                    checked={
                      _.findIndex(mappedAdmins, {
                        id: admin.id,
                      }) > -1
                    }
                  />
                  <ListItemText
                    primary={admin?.admin_name}
                    secondary={admin.email}
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          ): null}
        </Grid>
        <div
          className="formSection"
          style={{
            bottom: "10px",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "97%",
          }}
        >
          <Grid
            container
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{ maxWidth: "200px", minWidth: "200px" }}
            >
              {admin?.admindata?.id ? (
                <div>
                  {isValid ? (
                    <Button
                      variant="contained"
                      className="purple-add1"
                      type="submit"
                      style={{ backgroundColor: "gray" }}
                    >
                      <span>Update User</span>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="back1"
                      style={{ backgroundColor: "gray" }}
                      disabled
                    >
                      <span>Update User</span>
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  {isValid ? (
                    <Button
                      variant="contained"
                      className="purple-add1"
                      type="submit"
                      style={{ backgroundColor: "gray" }}
                    >
                      <span>Add User</span>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="back1"
                      type="submit"
                      style={{ backgroundColor: "gray" }}
                    >
                      <span>Add User</span>
                    </Button>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
      {branch.errorMessage && (
        <Snackbar
          open={branch.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {branch.errorMessage}
          </Alert>
        </Snackbar>
      )}
      {branch.successMessage && (
        <Snackbar
          open={branch.successMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {branch.successMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default AddAdmin;
