import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ReactSpeedometer from "react-d3-speedometer";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import File from "../../common/assets/images/File.svg";
import ImageView from "../../Dashboard/components/ImageView";
import NotificationView from "../../Dashboard/components/NotificationView";

import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Avatar,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import moment from "moment";
import Download from "../../common/assets/images/Download.svg";
import Delete from "../../common/assets/images/Delete.svg";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import { Box } from "@mui/system";
import TrendView from "./TrendView";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

import "../styles/DataEntry.scss";
//import { multiPartFileUpload } from "../reducers/patientDataThunk";
import GauzeChart from "./GauzeChart";
import { save } from "save-file";

import {
  setErrorMessage,
  setsuccessMessage,
  getFilesDetailsFromTable,
  getFilesPrescriptionsFromTable,
  submitDrOpinion,
  downloadReport,
  getSendOpinion,
  getDrOpinionByReading,
  setFormTab,
  deleteReport,
} from "../reducers/patientDataThunk";
import { ToastContainer, toast } from "react-toastify";

const DoctorOpinion = (props) => {
  const textColor = "#000000";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);

  const userdata = sessionStorage.getItem("user");
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};

  const [selectedFiles, setSelectedFiles] = useState("");
  const [listPrescription, setListPrescription] = useState([]);
  const [listCaseSheets, setListCaseSheets] = useState([]);
  const [listDiagReports, setlistDiagReports] = useState([]);
  const [listImagings, setListImagings] = useState([]);
  const [DrOpinion, setDrOpinion] = React.useState("");
  const [addScoreData, setAddScoreData] = useState({});
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [trend, setTrend] = React.useState(false);
  const [showSelectedFile, setSelectedFile] = useState([]);
  const [deleteSeleFile, setDelete] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pdfData, setPdfData] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);

  const study_id = patient.bmrndata?.study_id;
  const studyId = patient.bmrndata?.study_id;
  const reading = patient.bmrndata?.reading_id;
  const createdAt = patient.bmrndata?.createdAt;
  const baby_medical_record_number =
    patient.bmrndata?.baby_medical_record_number;
  const mother_name = selectedPatient?.mother_name;

  const scoreData = patient?.bmrndata.score;
  const loggedUserId = user.userProfile?.user_id;

  const Deletefile = (e, fileName) => {
    setSelectedFile(fileName);
    setDelete(true);
    setState({ open: true});
  };

  const TrendViewValue = () => {
    setSelectedPatient({ studyId, baby_medical_record_number, mother_name });
    setTrend(true);
  };

  useEffect(() => {
    const req = { studyId, reading };
    dispatch(getDrOpinionByReading(req))
      .unwrap()
      .then((resdata) => {
        setDrOpinion(resdata.response[0]);
      });
  }, []);

  React.useEffect(() => {
    const generateScore = {
      scoreData: scoreData,
      color: getColorCode(scoreData),
      risk: riskAssess(getColorCode(scoreData)),
    };

    setAddScoreData(generateScore);
  }, []);

  const riskAssess = (colorCode) => {
    // console.log("inside function Risk assess ");
    // console.log("the color code is", colorCode);
    if (colorCode === "#74D976") {
      return "Low Risk";
    }
    if (colorCode === "#F9D372") {
      return "Medium Risk";
    }
    if (colorCode === "#F56D6D") {
      return "High Risk";
    }
  };

  const getColorCode = (score) => {
    // console.log("inside function get color code ");
    // console.log("The score is", score);
    if (score >= 0 && score <= 5) {
      return "#74D976";
    }
    if (score > 5 && score <= 8) {
      return "#F9D372";
    }
    if (score > 8 && score <= 10) {
      return "#F56D6D";
    }
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...DrOpinion };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setDrOpinion(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const Puid = baby_medical_record_number + reading;
    let currentFile = selectedFiles[0];
    const newFormData = new FormData();
    const document_type = "Prescription";
    newFormData.append("document_type", document_type);
    newFormData.append("file", currentFile);
    newFormData.append("opinion", DrOpinion?.opinion);
    newFormData.append("prescription_data", DrOpinion?.prescription_data);
    newFormData.append("Puid", Puid);

    newFormData.append(
      "baby_medical_record_number",
      baby_medical_record_number
    );
    newFormData.append("reading", reading);
    // console.log("THe file in request is ", newFormData);
    // console.log("About to call Axios API");
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }

    //const hospital_id = 162;
    //newFormData.baby_medical_record_number = baby_medical_record_number;
    const req = { study_id, reading, loggedUserId, newFormData };
    // console.log(newFormData);
    dispatch(submitDrOpinion(req))
      .unwrap()
      .then((resdata) => {
        toast.success("Opinion Submitted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        const req = { baby_medical_record_number, reading };
        dispatch(getFilesDetailsFromTable(req));

        dispatch(getFilesPrescriptionsFromTable(req))
        .unwrap()
        .then((resdata) => {
          const Prescription = resdata.response[0].filter(
            (d) => d.document_type === "Prescription"
          );
          setListPrescription(Prescription);
        });
      });
      setSelectedFiles("");
  };

  React.useEffect(() => {
    const req = { baby_medical_record_number, reading };
    dispatch(getFilesDetailsFromTable(req))
      .unwrap()
      .then((resdata) => {
        const caseSheets = resdata.response[0].filter(
          (d) => d.document_type === "CaseSheets"
        );
        // console.log("Case sheets", caseSheets);
        setListCaseSheets(caseSheets);

        const diagReports = resdata.response[0].filter(
          (d) => d.document_type === "DiagReports"
        );
        setlistDiagReports(diagReports);

        const imagings = resdata.response[0].filter(
          (d) => d.document_type === "Imagings"
        );
        setListImagings(imagings);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  React.useEffect(() => {
    const req = { baby_medical_record_number, reading };
    dispatch(getFilesPrescriptionsFromTable(req))
      .unwrap()
      .then((resdata) => {
        const Prescription = resdata.response[0].filter(
          (d) => d.document_type === "Prescription"
        );
        setListPrescription(Prescription);
      });
  }, []);

  const uploadRefImages = (event) => {
    setSelectedFiles(event.target.files);
    console.log('ppppppppppp',event.target.files)
    // console.log("The selected file is, ", event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    
    toast.success("File Selected Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const downloadRep = (e, fileName) => {
    e.preventDefault();

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [addReferralData, setaddReferralData] = useState(
    patient.referralDoctorData
  );

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [setOpen] = React.useState(false);

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = (newState) => () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.open) setaddReferralData(patient.referralDoctorData);
  }, [patient.referralDoctorData, props.open]);

  useEffect(() => {
    if (patient.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 10000);
    }
  }, [patient.errorMessage]);

  useEffect(() => {
    if (patient.successMessage) {
      setTimeout(() => {
        dispatch(setsuccessMessage(""));
      }, 10000);
    }
  }, [patient.successMessage]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const closeDialog = (e) => {
    e.preventDefault();
    //props.onClose();
    setTrend(false);
  };

  const deleteRep = (e, showSelectedFile) => {
    e.preventDefault();

    dispatch(deleteReport({ filename: showSelectedFile }))
      .unwrap()
      .then((resdata) => {
        
        const req = { baby_medical_record_number, reading };
        dispatch(getFilesDetailsFromTable(req));
        toast.success("File Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(getFilesPrescriptionsFromTable(req))
        .unwrap()
        .then((resdata) => {
          const Prescription = resdata.response[0].filter(
            (d) => d.document_type === "Prescription"
          );
          setListPrescription(Prescription);
        });
        setDelete(false);
        
        //getReports();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const viewFile = async (e, fileName) => {
    e.preventDefault();
    setSelectedFile(fileName);
    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        setDialogOpen(true);
        setPdfData(resdata.Body.data);
        if (fileName.endsWith(".pdf")) {
          setFileType("pdf");
        } else if (fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
          setFileType("jpg");
        } else if (fileName.endsWith(".png")) {
          setFileType("png");
        } else {
          // console.log("Unknown file type");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        style={{ width: "auto" }}
        className="report-dialog"
      >
        <DialogTitle style={{ borderBottom: "0.8px solid #f0f1fc" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{showSelectedFile}</div>
            <div>
              <Link
                className="FIle-right-extreme"
                onClick={() => setDialogOpen(false)}
              >
                <img
                  src={Close}
                  //className="file-img-right-extreme"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {fileType && fileType === "pdf" ? (
            <NotificationView data={pdfData} action={setDialogOpen} />
          ) : fileType === "jpg" || "png" ? (
            <ImageView data={pdfData} action={setDialogOpen} />
          ) : null}
        </DialogContent>
      </Dialog>
      <ToastContainer />
      <div className="dataoutlet">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <form onSubmit={HandleAddFormSubmit}>
              <Card variant="none">
                <CardContent>
                  <p className="pt_info">Opinion</p>
                  <hr />
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <label
                        htmlFor="Dr Opinion"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Type your opinion below and upload prescriptions if any-
                      </label>
                      <TextField
                        id="opinion"
                        name="opinion"
                        variant="outlined"
                        className="dataentry_input_opinion"
                        size="small"
                        multiline
                        rows={5}
                        placeholder="Type your opinion here"
                        onChange={handleAddFormChange}
                        value={DrOpinion?.opinion}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <label
                        htmlFor="Pprescription"
                        className="dataentry_label"
                        style={{ display: "block" }}
                      >
                        Prescription
                      </label>
                      <TextField
                        id="prescription_data"
                        name="prescription_data"
                        variant="outlined"
                        className="dataentry_input_opinion"
                        size="small"
                        multiline
                        rows={5}
                        placeholder="Enter your prescription here"
                        onChange={handleAddFormChange}
                        value={DrOpinion?.prescription_data}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                <>
                                {selectedFiles && (
                                   <ListItem>
                                   <Grid container>
                                     <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       style={{
                                         display: "flex",
                                         flexDirection: "row",
                                         justifyContent: "space-between",
                                       }}
                                     >
                                       <div style={{ display: "flex" }}>
                                         <ListItemIcon className="file-icon">
                                           <Link to="/" className="FIle-ref">
                                             {" "}
                                             <img
                                               src={File}
                                               className="file-img"
                                             />
                                           </Link>
                                         </ListItemIcon>
                                         <div
                                           className="file-name"
                                           title={selectedFiles[0].name}
                                         >
                                           {selectedFiles[0].name}
                                         </div>
                                       </div>
                                     </Grid>
                                   </Grid>
                                 </ListItem>
                                )}
                                </>
                                <>
                                {listPrescription.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              //aria-label="download"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                                color="primary"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          {allowedPermissions &&
                                            allowedPermissions.reading &&
                                            allowedPermissions.reading.indexOf(
                                              "C"
                                            ) != -1 && (
                                              <ListItemIcon className="file-align file-action-icons">
                                                <IconButton
                                                  className="acrossBtn"
                                                  aria-label="delete"
                                                  onClick={(e) =>
                                                    Deletefile(
                                                      e,
                                                      sheet.s3_filename
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={Delete}
                                                    className="Delete-img"
                                                  />
                                                </IconButton>
                                              </ListItemIcon>
                                            )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                                </>
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={4} className="uploadClassdevice1">
                      <label htmlFor="btn-upload-referral-diag">
                        <input
                          id="btn-upload-referral-diag"
                          name="btn-upload-diag"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadRefImages}
                        />
                        <Button
                          className="upload-reports"
                          color="primary"
                          variant="contained"
                          component="span"
                          size="small"
                        >
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={4} className="uploadClassdevice1">
                      <Button
                        variant="contained"
                        className="purple"
                        type="submit"
                        onClick={handleClick({
                          vertical: "top",
                          horizontal: "center",
                        })}
                        color="secondary"
                      >
                        <span>Submit Opinion</span>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>

            {deleteSeleFile && (
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{ borderBottom: "1px solid #000" }}>
                  <Link
                    to="/"
                    className="FIle-left"
                    onClick={handleClose}
                    style={{ marginRight: "20px" }}
                  >
                    <img
                      src={Back}
                      className="file-img-left"
                      alt=""
                      style={{ width: "12px" }}
                    />
                  </Link>
                  <span>Delete Conformation</span>
                  <Link
                    to="/"
                    className="FIle-right-extreme"
                    onClick={handleClose}
                  >
                    <img
                      src={Close}
                      className="file-img-right-extreme"
                      alt=""
                    />
                  </Link>
                </DialogTitle>
                <DialogContent>
                  <Box>
                    <form id="bmrn-form">
                      <Card style={{ marginTop: 10 }} variant="none">
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Alert severity="error">
                                <AlertTitle>
                                  Are you sure you want to delete this
                                  Prescription Document
                                </AlertTitle>
                              </Alert>
                            </Grid>
                            <div className="formSection">
                              <Grid container>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={4}>
                                  <Button
                                    variant="contained"
                                    className="back"
                                    onClick={handleClose}
                                  >
                                    Back
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Button
                                    variant="contained"
                                    className="purple-add"
                                    type="submit"
                                    onClick={(e) =>
                                      deleteRep(e, showSelectedFile)
                                    }
                                  >
                                    <span>Delete</span>
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </CardContent>
                      </Card>
                    </form>
                  </Box>
                </DialogContent>
              </Dialog>
            )}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card variant="none">
              <CardContent>
                <p className="pt_info">Patient Information</p>
                <hr />
                <div className="formSection">
                  <Grid container>
                    <Grid item xs={12} sm={7}>
                      <Grid container>
                        <Grid item xs={12} sm={6} className="pt_info_details">
                          BMRN
                          <br />
                          <span className="ptinfo_data">
                            {baby_medical_record_number}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={6} className="pt_info_details">
                          Date
                          <br />
                          <span className="ptinfo_data">
                            {moment(createdAt).format("DD-MM-YYYY")}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} className="pt_info_details">
                          <Button
                            variant="contained"
                            size="small"
                            className="pt_info_viewtrend"
                            onClick={() => TrendViewValue()}
                          >
                            View Trend
                          </Button>
                          {trend && (
                            <TrendView
                              selectedPatient={selectedPatient}
                              open={trend}
                              onClose={closeDialog}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} className="pt_info_details">
                      Latest Score
                      <div className="GauzeChart_ref">
                        {/*  <ReactSpeedometer
                          width={180}
                          height={150}
                          forceRender={true}
                          maxSegmentLabels={1}
                          customSegmentStops={[0, 5, 8, 10]}
                          segmentColors={["#74d976", "#f9d372", "#f56d6d"]}
                          needleColor={addScoreData?.color}
                          currentValueText={addScoreData?.risk}
                          minValue={0}
                          maxValue={10}
                          value={addScoreData?.scoreData}
                          textColor={textColor}
                          ringWidth={20}
                          needleHeightRatio={0.7}
                        /> */}
                        <GauzeChart data={addScoreData} />
                      </div>
                    </Grid>
                  </Grid>

                  <p className="dr_opi_rpts">Uploaded Reports</p>

                  <form id="reports-generation">
                    <div className="round-bordered1">
                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Case Sheets
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listCaseSheets.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Diagnostic Reports{}
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listDiagReports.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-header">
                        <Grid container className="CaseSheets">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="file-name"
                            style={{ textAlign: "left" }}
                          >
                            Imagings (X-rays,CT Scans)
                          </Grid>
                        </Grid>
                      </div>
                      <div className="report-content">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className="No_reports_Found"
                          >
                            <div>
                              <List>
                                {listImagings.map((sheet) => (
                                  <ListItem>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-icon">
                                            <Link to="/" className="FIle-ref">
                                              {" "}
                                              <img
                                                src={File}
                                                className="file-img"
                                              />
                                            </Link>
                                          </ListItemIcon>
                                          <div
                                            className="file-name"
                                            title={sheet.s3_filename}
                                          >
                                            {sheet.s3_filename}
                                          </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <ListItemIcon className="file-align file-action-icons">
                                            <IconButton
                                              className="acrossBtn"
                                              onClick={(e) =>
                                                viewFile(e, sheet.s3_filename)
                                              }
                                            >
                                              <ImageSearchIcon
                                                className="DownLoad-img"
                                                color="primary"
                                                style={{
                                                  width: "0.8em",
                                                  height: "0.8em",
                                                }}
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                          <ListItemIcon className="file-ref">
                                            <IconButton
                                              aria-label="acrossBtn"
                                              onClick={(e) =>
                                                downloadRep(
                                                  e,
                                                  sheet.s3_filename
                                                )
                                              }
                                            >
                                              <img
                                                src={Download}
                                                className="DownLoad-img"
                                              />
                                            </IconButton>
                                          </ListItemIcon>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                ))}
                              </List>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </form>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default DoctorOpinion;
