import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  InputAdornment,
} from "@mui/material";
import moment from "moment";

const Numerics = /^[0-9 .]*$/;
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../styles/DataEntry.scss";
import { CRYSOUND, SKIN_COLOR } from "../utils/mockData";
import {
  babyAppearAddition,
  updatePatientFormData,
  setBabyApperanceData,
  getbabyAppearbyreadingid,
  setFormTab,
  getMotherProfileByReadingId,
  getBabyProfileByReadingId,
  getBabyBranch,
  getWardsByBranch,
  getPatientLastReadingData,
  getPatientBasicDetails,
} from "../reducers/patientDataThunk";
import { ToastContainer, toast } from "react-toastify";
import { initialState } from "../reducers/InitialState";
import CircularProgress from "@mui/material/CircularProgress";

const BabyApperance = (props) => {
  const [defaultCheck, setDefaultCheck] = useState({});
  const patient = useSelector((state) => state.patient);
  const dbbranchdata = useSelector((state) => state.dashboard);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const hospital_id = user.userProfile.hospital_id;
  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const reading = patient?.Selectedreadingid;
  const readingId =
    JSON.stringify(reading) != "{}" ? reading : patient?.bmrndata?.reading_id;
  const ptbmrn = patient.bmrndata?.baby_medical_record_number;
  const [dischargeFlag, setDischargeFlag] = useState("1");

  const loggedUserId = user.userProfile?.user_id;
  const [readingDate, setReadingDate] = React.useState(moment().format());
  const baby_date_of_admission = patient.getBabyProfile?.baby_date_of_admission;
  const [addFormData, setAddbabyappearance] = useState(
    Object.assign({ reading_date: new Date() })
  );
  console.log('jjjjjjj',addFormData)

  const [warddata, setwarddata] = React.useState([]);
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showTimeOfReadingHoursErrorMessage,
    setShowTimeOfReadingHoursErrorMessage,
  ] = useState();
  const [
    showTimeOfReadingMinsErrorMessage,
    setShowTimeOfReadingMinsErrorMessage,
  ] = useState();
  const [showBabyWtAtBirthErrorMessage, setShowBabyWtAtBirthErrorMessage] =
    useState();
  const [showBabyCrySoundErrorMessage, setShowBabyCrySoundErrorMessage] =
    useState();
  const [
    showHypothermiaStatusValueErrorMessage,
    setShowHypothermiaStatusValueErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };
  useEffect(() => {
    const req = { baby_medical_record_number: ptbmrn };
    dispatch(getPatientBasicDetails(req))
      .unwrap()
      .then((resdata) => {
        const disFlag = resdata.response.discharge_flag;
        setDischargeFlag(disFlag);
      });
  }, [ptbmrn]);

  let url = `patient/update/baby_appears`;
  //const reading = patient.globalRecord?.reading_id;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (patient?.bmrndata?.study_id && user.userProfile.hospital_id) {
      dispatch(getBabyBranch({ studyid: patient?.bmrndata?.study_id })).then(
        (data) => {
          if (data?.payload?.response && data?.payload?.response[0]) {
            getWardDetailsByBranch(
              data?.payload?.response[0].hospital_branch_id
            );
          }
        }
      );
    }
  }, [patient?.bmrndata?.study_id, user.userProfile.hospital_id]);

  const getWardDetailsByBranch = (branch_id) => {
    const req = { hospital_id, branch_id };
    dispatch(getWardsByBranch(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          setwarddata(resdata.response);
        } else {
          setwarddata([]);
        }
      });
  };

  useEffect(() => {
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  }, []);

  useEffect(() => {
    setAddbabyappearance(patient.babyappearance || initialState.babyappearance);
  }, [patient.babyappearance]);

  useEffect(() => {
    if (readingId) {
      updateData();
    }
  }, [readingId]);

  const updateData = (event) => {
    const req = { readingId, studyid };
    dispatch(getbabyAppearbyreadingid(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          let ApperanceData = {
            response: Object.assign({}, resdata.response),
          };
          const data =
            ApperanceData.response && ApperanceData.response
              ? ApperanceData.response
              : undefined;
          const data1 =
            ApperanceData.response && ApperanceData.response.id
              ? setUpdateRecord(true)
              : setUpdateRecord(false);
          if (resdata.response) {
            setAddbabyappearance(
              ApperanceData.response && ApperanceData.response
              ? Object.assign(
                { reading_date: new Date() },
                ApperanceData.response
              )
            : { reading_date: new Date() }
            );
          }
        } else {
          const study_id = patient?.bmrndata?.study_id;
          //const req = { studyid };
          dispatch(getPatientLastReadingData(study_id))
            .unwrap()
            .then((resdata) => {
              if (resdata.response) {
                let ApperanceData = {
                  response: Object.assign({}, resdata.response.baby_appears),
                };
                const data =
                  ApperanceData.response && ApperanceData.response
                    ? ApperanceData.response
                    : undefined;
                const data1 =
                  ApperanceData.response && ApperanceData.response.id
                    ? setUpdateRecord(false)
                    : setUpdateRecord(false);
                if (resdata.response) {
                  setAddbabyappearance(
                    ApperanceData.response && ApperanceData.response
                      ? ApperanceData.response
                      : initialState.babyappearance
                  );
                }
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const verifyTimeOfReadingHours = (event) => {
    if (event.target.value >= 0 && event.target.value <= 23) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_hours: false,
        })
      );
      setShowTimeOfReadingHoursErrorMessage("");
    } else {
      setShowTimeOfReadingHoursErrorMessage(
        "Time of Reading Hours Should be Between 0 - 23"
      );
      //document.getElementById("TimeOfReadingHours").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_hours: true,
        })
      );
    }
  };

  const verifyTimeOfReadingMins = (event) => {
    if (event.target.value >= 1 && event.target.value <= 59) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_minute: false,
        })
      );
      setShowTimeOfReadingMinsErrorMessage("");
    } else {
      setShowTimeOfReadingMinsErrorMessage(
        "Time of Reading Mins Should be Between 1 - 59"
      );
      //document.getElementById("TimeOfReadingMins").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_minute: true,
        })
      );
    }
  };

  const verifyWeightAtBirth = (event) => {
    if (event.target.value >= 0.1 && event.target.value <= 20) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_weight_at_birth: false,
        })
      );
      setShowBabyWtAtBirthErrorMessage("");
    } else {
      setShowBabyWtAtBirthErrorMessage(
        "Weight of Birth Should be Between 0.1 - 20"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_weight_at_birth: true,
        })
      );
    }
  };

  const verifyBabyCrySound = (event) => {
    if (event.target.value >= 1 && event.target.value <= 300) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_cry_sound_status: false,
        })
      );
      setShowBabyCrySoundErrorMessage("");
    } else {
      setShowBabyCrySoundErrorMessage(
        "Baby Cry Sound Duration Should be Between 1 - 300"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_cry_sound_status: true,
        })
      );
    }
  };

  const verifyHypothermiaStatusValue = (event) => {
    if (event.target.value >= 1 && event.target.value <= 99) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          hypothermia_status_value: false,
        })
      );
      setShowHypothermiaStatusValueErrorMessage("");
    } else {
      setShowHypothermiaStatusValueErrorMessage(
        "Hypothermia Status Value Should be Between 1 - 99"
      );
      //document.getElementById("HypothermiaStatusValue").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          hypothermia_status_value: true,
        })
      );
    }
  };

  const verifyData = async () => {
    let validData = true;
    if (
      addFormData?.time_of_reading_hours < 0 ||
      addFormData?.time_of_reading_hours >= 24
    ) {
      toast.error("Time Of Reading Hours Should be Between 0 - 23", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("WeightAtBirth").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_hours: true,
        })
      );

      validData = false;
    }
    if (
      addFormData?.time_of_reading_minute < 0 ||
      addFormData?.time_of_reading_minute >= 60
    ) {
      toast.error("Time Of Reading Minutes Should be Between 0 - 59", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("WeightAtBirth").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          time_of_reading_minute: true,
        })
      );

      validData = false;
    }
    if (
      addFormData?.baby_weight_at_birth == 0 ||
      addFormData?.baby_weight_at_birth >= 50
    ) {
      toast.error("Weight of Birth Should be Between 1 - 50", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //document.getElementById("WeightAtBirth").focus();
      setErrorFields(
        Object.assign({}, errorFields, {
          baby_weight_at_birth: true,
        })
      );

      validData = false;
    }
    if (
      addFormData?.baby_cry_sound_status == 0 ||
      addFormData?.baby_cry_sound_status >= 101
    ) {
      toast.error("Baby Cry Sound Duration Should be Between 1 - 100", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(
        Object.assign({}, errorFields, { baby_cry_sound_status: true })
      );
      validData = false;
    }
    if (
      addFormData?.hypothermia_status_value == 0 ||
      addFormData?.hypothermia_status_value >= 101
    ) {
      toast.error("Hypothermia Status Value Should be Between 1 - 100", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorFields(
        Object.assign({}, errorFields, { hypothermia_status_value: true })
      );
      validData = false;
    }

    return validData;
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldname] = fieldvalue;

    setAddbabyappearance(newFormData);
    setBabyApperanceData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();

    await submitData(4);
    const req = { study_id };
    dispatch(getMotherProfileByReadingId(req));
    dispatch(getBabyProfileByReadingId(req));
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addFormData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.loggedUserId = loggedUserId;
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;
    if (!updateRecord) {
      dispatch(babyAppearAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          const req = { readingId, studyid };
          dispatch(getbabyAppearbyreadingid(req));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // console.log("Inside baby cns update");

      newFormData.reading = patient.Selectedreadingid;
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getbabyAppearbyreadingid(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
          // console.log(resdata);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {isLoading ? (
        <div
          style={{
            height: "300px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Card variant="none">
            <CardContent>
              <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Record Id"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Record Id
                    </label>
                    <TextField
                      id="study_id"
                      name="study_id"
                      label=""
                      variant="outlined"
                      value={patient.bmrndata?.study_id}
                      onChange={handleAddFormChange}
                      className="dataentry_input"
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="ward"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Ward
                    </label>
                    <Select
                      labelId="Ward"
                      id="ward"
                      name="ward_id"
                      label=""
                      size="small"
                      className="dataentry_select_edu"
                      value={addFormData?.ward_id}
                      onChange={handleAddFormChange}
                      disabled={dischargeFlag == "0"}
                    >
                      {/* <MenuItem value="Select Ward">Select Ward</MenuItem> */}
                      {warddata && warddata.length
                        ? warddata.map((num) => (
                            <MenuItem value={num.ward_id}>
                              {num.ward_name}
                            </MenuItem>
                          ))
                        : undefined}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor=" Reading Date"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Reading Date
                    </label>
                    <DatePicker
                      id="reading_date"
                      size="small"
                      name="reading_date"
                      className="dataentry_input"
                      inputFormat="DD/MM/YYYY"
                      maxDate={moment().format()}
                      minDate={baby_date_of_admission}
                      InputLabelProps={{ shrink: true }}
                      value={addFormData?.reading_date}
                      disabled={dischargeFlag == "0"}
                      onChange={(date) => {
                        setReadingDate(date["$d"]);
                        handleAddFormChange({
                          target: { name: "reading_date", value: date["$d"] },
                        });
                      }}
                         autocomplete="off"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="dataentry_input"
                          helperText={null}
                          onKeyDown={(e) => {
                            e.preventDefault();
                         }}
                          //disabled
                          //disabled={dischargeFlag == "0"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Admission Type"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Time of Reading (0-23 Hours)
                    </label>
                    <Grid container>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="TimeOfReadingHours"
                          name="time_of_reading_hours"
                          className={
                            "dataentry_time " +
                            (errorFields.time_of_reading_hours
                              ? "errorField"
                              : "")
                          }
                          disabled={dischargeFlag == "0"}
                          variant="outlined"
                          size="small"
                          value={addFormData?.time_of_reading_hours}
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyTimeOfReadingHours(e);
                          }}
                          error={
                            errorFields.time_of_reading_hours &&
                            showTimeOfReadingHoursErrorMessage
                          }
                          errorText={showTimeOfReadingHoursErrorMessage}
                          helperText={
                            errorFields.time_of_reading_hours &&
                            showTimeOfReadingHoursErrorMessage
                              ? showTimeOfReadingHoursErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowTimeOfReadingHoursErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_reading_hours: true,
                                })
                              );
                            }
                          }}
                          
                          InputProps={{
                            inputProps: {
                              maxLength: 2,
                            },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Hrs
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="TimeOfReadingMins"
                          name="time_of_reading_minute"
                          className={
                            "dataentry_time " +
                            (errorFields.time_of_reading_minute
                              ? "errorField"
                              : "")
                          }
                          disabled={dischargeFlag == "0"}
                          variant="outlined"
                          size="small"
                          value={addFormData?.time_of_reading_minute}
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyTimeOfReadingMins(e);
                          }}
                          error={
                            errorFields.time_of_reading_minute &&
                            showTimeOfReadingMinsErrorMessage
                          }
                          errorText={showTimeOfReadingMinsErrorMessage}
                          helperText={
                            errorFields.time_of_reading_minute &&
                            showTimeOfReadingMinsErrorMessage
                              ? showTimeOfReadingMinsErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowTimeOfReadingMinsErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  time_of_reading_minute: true,
                                })
                              );
                            }
                          }}
                          InputProps={{
                            inputProps: {
                              maxLength: 2,
                            },
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="InputAdornment"
                              >
                                Mins
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Record Id"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Weight of Baby
                    </label>
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          id="WeightAtBirth"
                          label=""
                          name="baby_weight_at_birth"
                          value={addFormData?.baby_weight_at_birth}
                          variant="outlined"
                          size="small"
                          disabled={dischargeFlag == "0"}
                          className={
                            "dataentry_input " +
                            (errorFields.baby_weight_at_birth
                              ? "errorField"
                              : "")
                          }
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyWeightAtBirth(e);
                          }}
                          error={
                            errorFields.baby_weight_at_birth &&
                            showBabyWtAtBirthErrorMessage
                          }
                          errorText={showBabyWtAtBirthErrorMessage}
                          helperText={
                            errorFields.baby_weight_at_birth &&
                            showBabyWtAtBirthErrorMessage
                              ? showBabyWtAtBirthErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowBabyWtAtBirthErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  baby_weight_at_birth: true,
                                })
                              );
                            }
                          }}
                          InputProps={{
                            inputProps: {
                              maxLength: 5,
                              minLength: 1,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Select
                          labelId="wt"
                          id="wt"
                          name="baby_weight_at_birth_unit"
                          value={
                            addFormData?.baby_weight_at_birth_unit || "kgs"
                          }
                          label=""
                          onChange={handleAddFormChange}
                          size="small"
                          className="dataentry_select"
                          disabled={dischargeFlag == "0"}
                        >
                          <MenuItem value="kgs">Kgs</MenuItem>
                          <MenuItem value="lbs">Lbs</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Record Id"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Appearance
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.baby_appearance}
                      name="baby_appearance"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      //required
                    >
                      <ToggleButton
                        name="baby_appearance"
                        value="Dull"
                        size="small"
                        className="dataentry_input"
                      >
                        Dull
                      </ToggleButton>
                      <ToggleButton
                        name="baby_appearance"
                        value="Lethargic"
                        size="small"
                        className="dataentry_input"
                      >
                        Lethargic
                      </ToggleButton>
                      <ToggleButton
                        name="baby_appearance"
                        value="Normal"
                        size="small"
                        className="dataentry_input"
                      >
                        Normal
                      </ToggleButton>
                      <ToggleButton
                        name="baby_appearance"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Skin Colour"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Skin Colour
                      {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <Select
                      labelId="baby_skin_colour"
                      id="baby_skin_colour"
                      name="baby_skin_colour"
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addFormData?.baby_skin_colour}
                      disabled={dischargeFlag == "0"}
                    >
                      {SKIN_COLOR.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Cry Sound"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Cry Sound
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <Select
                      labelId="Baby Cry Sound"
                      id="baby_cry_sound"
                      name="baby_cry_sound"
                      label=""
                      disabled={dischargeFlag == "0"}
                      onChange={handleAddFormChange}
                      size="small"
                      className="dataentry_select_edu"
                      value={addFormData?.baby_cry_sound}
                    >
                      {CRYSOUND.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Cry Sound"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Duration(If Not Cried)
                    </label>
                    <TextField
                      id="BabyCrySound"
                      label=""
                      name="baby_cry_sound_status"
                      value={addFormData?.baby_cry_sound_status}
                      variant="outlined"
                      disabled={dischargeFlag == "0"}
                      size="small"
                      className={
                        "dataentry_input " +
                        (errorFields.baby_cry_sound_status ? "errorField" : "")
                      }
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyBabyCrySound(e);
                      }}
                      error={
                        errorFields.baby_cry_sound_status &&
                        showBabyCrySoundErrorMessage
                      }
                      errorText={showBabyCrySoundErrorMessage}
                      helperText={
                        errorFields.baby_cry_sound_status &&
                        showBabyCrySoundErrorMessage
                          ? showBabyCrySoundErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowBabyCrySoundErrorMessage(
                            "Only Numerics are Acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              baby_cry_sound_status: true,
                            })
                          );
                        }
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 3,
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className="InputAdornment"
                          >
                            Secs
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Hypotonia Muscular Response (1 min after birth)"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Hypotonia Muscular Response (1 min after birth)
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={
                        addFormData?.hypotonia_muscular_response_one_min_after_birth
                      }
                      name="hypotonia_muscular_response_one_min_after_birth"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      //required
                    >
                      <ToggleButton
                        name="hypotonia_muscular_response_one_min_after_birth"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="hypotonia_muscular_response_one_min_after_birth"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="hypotonia_muscular_response_one_min_after_birth"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Hypotonia Muscular Response (5 min after birth)"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Hypotonia Muscular Response (5 min after birth)
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={
                        addFormData?.hypotonia_muscular_response_five_min_after_birth
                      }
                      name="hypotonia_muscular_response_five_min_after_birth"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="hypotonia_muscular_response_five_min_after_birth"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="hypotonia_muscular_response_five_min_after_birth"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="hypotonia_muscular_response_five_min_after_birth"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Hypothermia"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Hypothermia
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.hypothermia}
                      name="hypothermia"
                      id="hypothermia"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="hypothermia"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="hypothermia"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="hypothermia"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  {addFormData.hypothermia  == "YES" && (
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Weight At Birth"
                      style={{ display: "block" }}
                      className="dataentry_label"
                    >
                      Hypothermia (Units)
                    </label>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="HypothermiaStatusValue"
                          label=""
                          name="hypothermia_status_value"
                          
                          value={addFormData?.hypothermia_status_value}
                          variant="outlined"
                          disabled={dischargeFlag == "0"}
                          size="small"
                          className={
                            "dataentry_input " +
                            (errorFields.hypothermia_status_value
                              ? "errorField"
                              : "")
                          }
                          onChange={(e) => {
                            handleAddFormChange(e);
                            verifyHypothermiaStatusValue(e);
                          }}
                          error={
                            errorFields.hypothermia_status_value &&
                            showHypothermiaStatusValueErrorMessage
                          }
                          errorText={showHypothermiaStatusValueErrorMessage}
                          helperText={
                            errorFields.hypothermia_status_value &&
                            showHypothermiaStatusValueErrorMessage
                              ? showHypothermiaStatusValueErrorMessage
                              : ""
                          }
                          onKeyDown={(event) => {
                            if (
                              !Numerics.test(event.key) &&
                              event.key != "Backspace"
                            ) {
                              event.preventDefault();
                              setShowHypothermiaStatusValueErrorMessage(
                                "Only Numerics are Acceptable"
                              );
                              setErrorFields(
                                Object.assign({}, errorFields, {
                                  hypothermia_status_value: true,
                                })
                              );
                            }
                          }}
                          
                      InputProps={{
                        inputProps: {
                          maxLength: 2,
                        },
                      }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Select
                          labelId="hypothermia_status"
                          id="hypothermia_status"
                          name="hypothermia_status"
                          value={addFormData?.hypothermia_status}
                          label=""
                          onChange={handleAddFormChange}
                          size="small"
                          className="dataentry_select1"
                          disabled={dischargeFlag == "0"}
                        >
                          <MenuItem value="Centigrade">Centigrade</MenuItem>
                          <MenuItem value="Fahrenheit">Fahrenheit</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Excessive Sleeping"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Excessive Sleeping
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.excessive_sleeping}
                      name="excessive_sleeping"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="excessive_sleeping"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="excessive_sleeping"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="excessive_sleeping"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="baby_feeding_status"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Feeding Status
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.baby_feeding_status}
                      name="baby_feeding_status"
                      id="diagnosis"
                      exclusive
                      //required
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="baby_feeding_status"
                        value="Poor"
                        size="small"
                        className="dataentry_input"
                      >
                        Poor
                      </ToggleButton>
                      <ToggleButton
                        name="baby_feeding_status"
                        value="Normal"
                        size="small"
                        className="dataentry_input"
                      >
                        Normal
                      </ToggleButton>
                      <ToggleButton
                        name="baby_feeding_status"
                        value="No Feeding"
                        size="small"
                        className="dataentry_input"
                      >
                        No Feeding
                      </ToggleButton>
                      <ToggleButton
                        name="baby_feeding_status"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="baby_presence_of_convulsions"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Presence Of Convulsions
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.baby_presence_of_convulsions}
                      name="baby_presence_of_convulsions"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="baby_presence_of_convulsions"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="baby_presence_of_convulsions"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="baby_presence_of_convulsions"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="baby_jaundice"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Jaundice (Present)
                      {/*  <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.baby_jaundice}
                      name="baby_jaundice"
                      id="diagnosis"
                      exclusive
                      //required
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="baby_jaundice"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="baby_jaundice"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="baby_jaundice"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="breast_feeding_initiation"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Breast Feeding Initiation
                      {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.breast_feeding_initiation}
                      name="breast_feeding_initiation"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                      //required
                    >
                      <ToggleButton
                        name="breast_feeding_initiation"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="breast_feeding_initiation"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="breast_feeding_initiation"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="Hypotonia Muscular Response (5 min after birth)"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Kangaroo Mother Care
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.kangaroo_mother_care}
                      name="kangaroo_mother_care"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="kangaroo_mother_care"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="kangaroo_mother_care"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="kangaroo_mother_care"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="umbilical_redness"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Umbilical Redness
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.umbilical_redness}
                      name="umbilical_redness"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="umbilical_redness"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_redness"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_redness"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="umbilical_enduration"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Umbilical Enduration
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.umbilical_enduration}
                      name="umbilical_enduration"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="umbilical_enduration"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_enduration"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_enduration"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="umbilical_discharge"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Umbilical Discharge
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.umbilical_discharge}
                      name="umbilical_discharge"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="umbilical_discharge"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_discharge"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="umbilical_discharge"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label
                      htmlFor="skin_pustules"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Skin Pustules {">"} 5
                    </label>
                    <ToggleButtonGroup
                      color="primary"
                      value={addFormData?.skin_pustules}
                      name="skin_pustules"
                      id="diagnosis"
                      exclusive
                      onChange={handleAddFormChange}
                      className="Toggle_input"
                    >
                      <ToggleButton
                        name="skin_pustules"
                        value="YES"
                        size="small"
                        className="dataentry_input"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        name="skin_pustules"
                        value="NO"
                        size="small"
                        className="dataentry_input"
                      >
                        No
                      </ToggleButton>
                      <ToggleButton
                        name="skin_pustules"
                        value="NA"
                        size="small"
                        className="dataentry_input"
                      >
                        NA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  {isValid ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      aria-label="add"
                      className="buttonStyle sign-up-button"
                      type="submit"
                      disabled={dischargeFlag == "0"}
                      style={{ cursor: "pointer" }}
                    >
                      Save {"&"} Next
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="gray"
                      aria-label="add"
                      className="buttonStyle1 sign-up-button"
                      disabled
                    >
                      Save {"&"} Next
                    </Button>
                  )}
                </div>
              </form>
            </CardContent>
          </Card>
        </LocalizationProvider>
      )}
    </React.Fragment>
  );
};

export default BabyApperance;
